/* eslint-disable indent */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./style.scss";
import { Table, message, Spin } from "antd";
import Heading from "../../components/Heading";
import { useNavigate } from "react-router-dom";
import back_img from "../../assests/images/rightVector.svg";
import { useLocation } from "react-router";
import nodeAPI from "../../api/nodeUrls";

import {
  bookingInfoCol,
  customisezColoum,
  travelInfoColSecondPage,
  auditCol,
} from "./columns";
import API from "../../api";
import urls from "../../api/urls";
import { useEffect } from "react";
import Smcs_Loader from "../../assests/images/loader-new.gif";
import { camelCase } from "../../utils";

function ShipmentTracker() {
  const history = useLocation();
  const navigate = useNavigate();

  const { SHIPMENT_TRACKER_NODE } = nodeAPI;
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({});
  const bagItemsStyle = { flexBasis: "190px" };
  const [bagorShipmentId, setBagorShipmentId] = useState("");
  const [navStack, setNavStack] = useState([]);
  let isReturn = tableData?.shipmentTracking?.isRTO;

  const getShipOrBagDetails = async (awbNumber) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setLoading(true);
    let response = await API.Api(
      SHIPMENT_TRACKER_NODE + awbNumber
    ).NodeGetWithPremise();
    if (response?.status) {
      const res = response?.response?.data;
      setLoading(false);
      if (res?.statusCode === 400) {
        message.error(res?.message || "Not found");
        window.history.back();
      }

      setBagorShipmentId(awbNumber);

      setTableData(res);
    } else {
      window.history.back();

      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  useEffect(() => {
    const inputLength = history?.state?.awb?.length;
    if (inputLength == 11 || inputLength == 14)
      getShipOrBagDetails(history?.state?.awb);
  }, [history?.state?.num]);

  const bagColor = {
    "#2E7FE1": "Blue",
    "#70C36E": "Green",
    "#D2DD3F": "Yellow",
    "#fff": "White",
    "#FFAB02": "Orange",
  };

  const bag = tableData?.baggingTracking?.baggingInfo;
  const bagShipment = [
    { itemName: "LCR Id", value: bag?.lcrId },
    {
      itemName: bag?.isCPBag?"Destination CP Name":"Destination Hub Name",
      value: bag?.isCPBag?camelCase(bag?.cpName):camelCase(bag?.destinationHubName),
    },
    { itemName: "Type", value: camelCase(bag?.bagType) },

    { itemName: "Color", value: bagColor[bag?.bagColourType] },
    { itemName: "Bag Weight", value: bag?.bagWeight?.toFixed(2) },
    {
      itemName: "Total Volumetric Weight",
      value: bag?.totalWeight?.toFixed(2),
    },
    { itemName: "Content Type", value: camelCase(bag?.contentType) },
    { itemName: "Product", value: camelCase(bag?.product) },
    { itemName: "Mode", value: camelCase(bag?.mode) },
  ];

  const handleShipOrBagOnClick = (awb) => {
    if (!navStack.includes(awb))
      setNavStack((prevNavStack) => [...prevNavStack, awb]);

    getShipOrBagDetails(awb);
  };

  const goBackToPrevScreen = () => {
    if (navStack.length > 0) {
      const prevAwb = navStack[navStack.length - 1];
      getShipOrBagDetails(prevAwb);
      setNavStack(navStack.slice(0, -1));
    } else {
      navigate(-1);
    }
  };
  
  const shipmentRoute = tableData?.shipmentTracking?.route_info;

  const shipmentRouteColoum = shipmentRoute?.map((_, index) => ({
    title:
      index == 0
        ? "Source Hub"
        : index == shipmentRoute.length - 1
        ? "To Hub"
        : `Transit ${index}`,
    dataIndex: `col${index}`,
    key: `col${index}`,
    width: 10,
  }));

  const dataSource = [
    shipmentRoute?.reduce(
      (acc, value, index) => {
        acc[`col${index}`] = camelCase(value);
        return acc;
      },
      { key: 1 }
    ),
  ];
  const bagRoute = tableData?.baggingTracking?.route_info;
  const bagRouteColoum = bagRoute?.map((_, index) => ({
    title:
      index == 0
        ? "Source Hub"
        : index == bagRoute.length - 1
        ? "To Hub"
        : `Transit ${index}`,
    dataIndex: `col${index}`,
    key: `col${index}`,
    width: 10,
  }));
  const bagDataSource = [
    bagRoute?.reduce(
      (acc, value, index) => {
        acc[`col${index}`] = camelCase(value);
        return acc;
      },
      { key: 1 }
    ),
  ];

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="consolidated-tab-cntr">
        <p className="master-title-head" style={{ marginBottom: "5px" }}>
          <img
            src={back_img}
            onClick={() => goBackToPrevScreen()}
            className="pointer"
          />
          <span>Track Shipment</span>
        </p>
        <div className="consolidated-manifest-cntr">
          {tableData?.shipmentTracking ? (
            <>
              <div className="new-header ml-1">
                Shipment ID: {bagorShipmentId}&nbsp;&nbsp;
                {isReturn && <span className="returnTxt">RETURN</span>}
              </div>
              <div className="new-header ml-1">Booking Info</div>
              <Table
                columns={bookingInfoCol}
                dataSource={[tableData?.shipmentTracking?.bookingInfoDto]}
                pagination={false}
                scroll={{
                  x: 900,
                }}
              />
              {shipmentRouteColoum?.length !== 0 && (
                <>
                  <div className="new-header ml-1">Shipment Route</div>
                  <Table
                    columns={shipmentRouteColoum}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{
                      x: 900,
                    }}
                  />
                </>
              )}

              <div className="new-header ml-1">Audit Info</div>
              <Table
                columns={auditCol}
                dataSource={tableData?.shipmentTracking?.auditInfo}
                pagination={false}
                scroll={{
                  x: 2000,
                }}
              />

              <div className="new-header ml-1">Travel Info</div>
              <Table
                columns={customisezColoum.reduce((acc, item, index) => {
                  if (index == 1) {
                    acc.push({
                      title: "Bag no",
                      dataIndex: "bagBarCode",
                      render: (text) => (
                        <>
                          {text ? (
                            <span
                              className="span-link"
                              onClick={() => handleShipOrBagOnClick(text)}
                            >
                              {text}
                            </span>
                          ) : (
                            <span> {"----"}</span>
                          )}
                        </>
                      ),
                    });
                  } else {
                    acc.push(item);
                  }
                  return acc;
                }, [])}
                dataSource={tableData?.shipmentTracking?.travelDtos}
                pagination={false}
                scroll={{
                  x: 900,
                }}
              />
            </>
          ) : (
            <>
              <div className="new-header ml-1">
                Bag Number: {bagorShipmentId}
              </div>
              <div className="new-header ml-1">Bag Info</div>
              <div
                className="bagInfoContainer"
                style={{
                  display: "flex",
                  flexGrow: "inherit",
                  backgroundColor: "#EAEAEA",
                }}
              >
                <div
                  className="newcontainer"
                  style={{ borderRight: "1px solid #BEBDBD", width: "45%" }}
                >
                  {bagShipment?.splice(0, 3)?.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="item">
                        <span className="label" style={bagItemsStyle}>
                          {item?.itemName}:
                        </span>
                        <span className="value">{item?.value}</span>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <div
                  className="newcontainer"
                  style={{ borderRight: "1px solid #BEBDBD", width: "45%" }}
                >
                  {bagShipment?.splice(3, 6)?.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="item">
                        <span className="label" style={bagItemsStyle}>
                          {item?.itemName}:
                        </span>
                        <span className="value">{item?.value}</span>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <div className="newcontainer" style={{ width: "45%" }}>
                  {bagShipment?.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="item">
                        <>
                          <span className="label" style={bagItemsStyle}>
                            {item?.itemName}:
                          </span>
                          <span className="value">{item?.value}</span>
                        </>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
              {bagRouteColoum?.length !== 0 && (
                <>
                  <div className="new-header ml-1">Bag Route</div>
                  <Table
                    columns={bagRouteColoum}
                    dataSource={bagDataSource}
                    pagination={false}
                    scroll={{
                      x: 900,
                    }}
                  />
                </>
              )}
              <div className="new-header ml-1">Travel Info</div>
              <Table
                columns={travelInfoColSecondPage}
                dataSource={tableData?.baggingTracking?.travelInfoDto}
                pagination={false}
              />
              <div className="new-header ml-1">Shipping Details</div>
              <Table
                columns={[
                  {
                    title: "Shipment No",
                    dataIndex: "number",
                    key: "number",
                    render: (text) => (
                      <span
                        className="span-link"
                        onClick={() => handleShipOrBagOnClick(text)}
                      >
                        {text}
                      </span>
                    ),
                  },
                ]}
                dataSource={tableData?.baggingTracking?.bagShipmentNo?.map(
                  (number, index) => ({
                    key: index,
                    number,
                  })
                )}
                pagination={false}
              />
            </>
          )}
        </div>
      </div>
    </Spin>
  );
}

export default ShipmentTracker;
