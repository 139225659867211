/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, Input, Form, Button, Table, Spin, message } from "antd";
import "./style.scss";
import API from "../../../api";
import urls from "../../../api/urls";
import {
  capitalize,
  checkAlphabets,
  checkSpace,
  checkSpecialCharacter,
  commonSorterForTable,
  firstLetterCapitalise,
  serialNum,
} from "../../../utils";
import Heading from "../../../components/Heading";
import { useLocation, useParams } from "react-router-dom";

import UpdateIcon from "../../../assests/images/updateEway.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

import whiteUpdateIcon from "../../../assests/images/whiteUpdateEway.svg";
// import MainEwayBill from "../EwayBillMain";
import { getModifiedGST, formattedToday } from "..";
import search_icon from "../../../assests/images/search-icon.svg";

const {
  CONSOLIDATE_EWAY_BILL,
  EWAYBILL_UPDATE_PARTB,
  AUDIT_EWAY_BILL,
  SORT_CP_UPDATED_EWAY_BILL,
  HUB_MANIFEST_LIST,
  HUB_LOCK_MANIFEST,
  UPDATE_LASTMILE_EWAYS,
} = urls;

const CPWiseHubManifestEWayBill = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const history = useLocation();

  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [dataSource, setdataSource] = useState(tableData);

  const userId = JSON.parse(localStorage.getItem("userId"));
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [updatedEwayData, setUpdatedEwayData] = useState([]);
  const [vehicleNum, setVehicleNum] = useState("");
  const [transPortMode, setTransPortMode] = useState("surface");
  const [inputSearchValue, setInputSearchValue] = useState("");

  const [enableLockManifest, setEnableLockManifest] = useState(true);
  const [sortedInfo, setSortedInfo] = useState({});

  useEffect(() => {
    getUpdatedEwayBills();
  }, []);

  useEffect(() => {
    getEwayBills();
  }, [updatedEwayData]);

  const columns = [
    {
      title: "SI No",
      dataIndex: "",
      key: "SI",
      width: 15,
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 10,
    },
    {
      title: "AWb No",
      dataIndex: "awbNumber",
      key: "awbNumber",
      width: 40,
      render: (text) => {
        return text;
      },
    },
    {
      title: "E-way Bill No",
      width: 30,
      dataIndex: "ewayBillNumber",
      key: "ewayBillNumber",
    },

    {
      title: "Destination CP",
      width: 40,
      dataIndex: "destinationCpName",
      key: "destinationCpName",
      sorter: (a, b) => commonSorterForTable(a, b, "destinationCpName"),
      sortOrder:
        sortedInfo?.columnKey === "destinationCpName"
          ? sortedInfo?.order
          : null,
      ellipsis: true,
      render: (record, _data) => {
        return capitalize(record) || "NA";
      },
    },
    {
      title: "Mode",
      width: 40,
      dataIndex: "travelBy",
      key: "travelBy",
      render: (record, _data) => {
        return capitalize(record) || "NA";
      },
    },

    {
      title: "Actions",

      dataIndex: "action",
      key: "action",
      width: 60,
      render: (record, _data) => {
        return (
          <div>
            {_data.ewayUpdated ? (
              <Button
                className=""
                // disabled={_data?.ewayUpdated}
                style={{
                  borderRadius: "5px",
                  color: "white",
                  background: "#149D11",
                  cursor: "not-allowed",
                }}
              >
                <img src={whiteUpdateIcon} />
                <span className="ml-1 fw-500">E-WAY BILL UPDATED</span>
              </Button>
            ) : (
              <Button
                className=""
                // disabled={_data?.ewayUpdated}
                style={{
                  borderRadius: "5px",
                  color: "#2E77D0",
                  background: "white",
                }}
                onClick={() => SingleConsolidate(_data)}
              >
                <img src={UpdateIcon} />
                <span className="ml-1 fw-500">E-WAY BILL UPDATE</span>
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const getEwayBills = async (e) => {
    setLoading(true);
    // form.setFieldsValue({
    //   vehicleNo: "",
    //   driverName: "",
    // });
    const response = await API.Api(
      HUB_MANIFEST_LIST + `/${params.id}`
    ).getWithAllHeaders();

    if (response) {
      setLoading(false);

      const res = response?.response?.data;
      const temp = [...res];
      for (let i = 0; i < temp?.length; i++) {
        const shipment = temp[i];
        const isUpdated = updatedEwayData.some(
          (data) => data.ewaybillNo === shipment.ewayBillNumber
        );
        temp[i].ewayUpdated = isUpdated;
      }
      setTableData(temp);
      setTransPortMode("surface");
    } else {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.ewayUpdated,
      name: record.name,
    }),
    selectedRowKeys: selectedRowKeys,
  };

  const handleSubmit = async (params) => {
    setLoading(true);
    const stateGST = getModifiedGST(userId);

    if (selectedRowKeys?.length > 0) {
      let temp = selectedRowKeys?.map((val) => {
        return { ewbNo: val };
      });

      let payload = {
        fromPlace: userId?.premises?.city,
        fromState: +stateGST,
        // vehicleNo: "KA32P1275" || params?.vehicleNo,
        vehicleNo: form.getFieldValue("vehicleNo"),
        transMode: transPortMode == "surface" ? "1" : "3",
        transDocDate: params.transDocDate,
        tripSheetEwbBills: temp,
      };

      let response = await API.Api(CONSOLIDATE_EWAY_BILL).postIdHeader(payload);
      
      if (response?.response?.data?.status == "1") {
        if (typeof response?.response?.data?.data === "string" && typeof JSON.parse(response?.response?.data?.data) !== "object") {
          setLoading(false);
          message.error(response?.response?.data?.message || "Failed to Update E-way bill");
          return;
        }
        setLoading(false);
        message.success(
          response?.response?.data?.message || "E-way Bill Updated Successfully"
        );
        if (response?.response?.data.failedEwayBills?.length > 0) {
          let failedEwayBills = response?.response?.data?.failedEwayBills;
          let failedEwayBillNumbers = failedEwayBills?.map((item) => item.ewbNo);
          let EwayBillData = selectedRowKeys.filter((item) => !failedEwayBillNumbers?.includes(item));
          auditEwayBill(response?.response?.data, EwayBillData, "consolidate");
        } else {
          auditEwayBill(response?.response?.data, selectedRowKeys, "consolidate");
        }
        setSelectedRowKeys([]);
      } else {
        setLoading(false);
        message.error(
          response?.response?.data?.data || "Failed to Update E-way bill"
        );
      }
    } else {
      message.error("Select an E-way bill");
      setLoading(false);
    }
  };

  async function SingleConsolidate(record) {
    setLoading(true);

    const stateGST = getModifiedGST(userId);

    let payload = {
      vehicleNo: form.getFieldValue("vehicleNo"),
      fromPlace: userId?.premises?.city,
      fromState: +stateGST,
      transDocDate: formattedToday,
      ewbNo: record.ewayBillNumber,
      transMode: transPortMode == "surface" ? "1" : "3",
    };

    let response = await API.Api(
      EWAYBILL_UPDATE_PARTB + record.ewayBillNumber + "/partB"
    ).patchIdHeader(payload);
    if (response?.response?.data?.status == "1") {
      setLoading(false);
      message.success(
        response?.response?.data?.message || "E-way Bill Updated Successfully"
      );
      auditEwayBill(response?.response?.data, record, "single");
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.data || "Failed to Update E-way bill"
      );
    }
  }

  async function auditEwayBill(singleUpdateRes, record, type) {
    setLoading(true);

    function filterDataByAwbNumbers(dataArray, awbNumbersArray) {
      return dataArray?.filter((item) =>
        awbNumbersArray?.includes(item?.ewayBillNumber)
      );
    }

    let temp = [];
    let payload = {};

    let x = singleUpdateRes?.data;
    let cNum = JSON?.parse(x);

    if (type == "single") {
      temp = tableData?.filter(
        (val) => val?.ewayBillNumber === record.ewayBillNumber
      );

      payload = {
        shipments: temp?.map((val) => {
          return {
            awbnumber: val?.awbNumber,
            ewaybillNo: val?.ewayBillNumber,
            ewayBillCrtDate: val?.ewayBillCreateDate || null,
            ewbBillExpDate: val?.expiryDate,
            awbcrtDate: val?.bookingStartTime,
            shipmentValue: val?.value,
          };
        }),
        manifestId: history?.state?.manifestId,
        consEwbNo: "0",
        vehicleNo: vehicleNum,
        transMode: "surface",
        ewbOprPlace: userId?.premises?.city,
      };
    } else {
      let filteredData = filterDataByAwbNumbers(tableData, record);
      temp = filteredData?.map((val) => val);

      payload = {
        shipments: temp?.map((val) => {
          return {
            awbnumber: val?.awbNumber,
            ewaybillNo: val?.ewayBillNumber,
            ewayBillCrtDate: val?.ewayBillCreateDate || null,
            ewbBillExpDate: val?.expiryDate,
            awbcrtDate: val?.bookingStartTime,
            shipmentValue: val?.value,
          };
        }),
        manifestId: history?.state?.manifestId,
        consEwbNo: cNum?.cEwbNo,
        vehicleNo: form.getFieldValue("vehicleNo"),
        transMode: "surface",
        ewbOprPlace: userId?.premises?.city,
      };
    }

    let response = await API.Api(AUDIT_EWAY_BILL).postIdHeader(payload);

    if (response.status) {
      setLoading(false);
      if (type == "single") {
        updateEwayBills(temp, null);
      } else {
        updateEwayBills(temp, cNum?.cEwbNo);
      }
    } else {
      setLoading(false);
      message.error("Internal server error");
    }
  }

  const updateEwayBills = async (data, cEwbNo) => {
    setLoading(true);

    let payload = {
      ewayBillList: data.map((val) => +val.ewayBillNumber),
      consolidatedEwayBill: cEwbNo || null,
    };

    let response = await API.Api(
      UPDATE_LASTMILE_EWAYS + params?.id
    ).putCustomHeader(payload);

    if (response?.status) {
      setLoading(false);
      getUpdatedEwayBills();
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.data || "Failed to Update E-way bill"
      );
    }
  };

  const getUpdatedEwayBills = async () => {
    setLoading(true);
    let response = await API.Api(
      `${SORT_CP_UPDATED_EWAY_BILL}${params?.id}`
    ).getWithAllHeaders();
    if (response?.status) {
      setLoading(false);
      setUpdatedEwayData(response?.response?.data?.ewaybill);
    } else {
      setLoading(false);
    }
  };

  const filterArray = (data, currValue) => {
    return data?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(tableData, currValue)
        : filterArray(tableData, currValue);
    setdataSource(filteredData);
  };

  async function handleLockManifest() {
    setLoading(true);

    const payload = {
      // status: "LOCKED",
      status: "LOCKED",
      vehicleNumber: form?.vehicleNo || "",
      driverName: form?.driverName || "",
    };
    let response = await API.Api(HUB_LOCK_MANIFEST + params?.id).putIdHeader(
      payload
    );

    if (response.status) {
      setLoading(false);
      message.success("Manifest locked successfully");
      getUpdatedEwayBills();
    } else {
      message.error("Network error");
      setLoading(false);
    }
  }

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  // console.log(history?.state?.driverName, "history");

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="m-2">
        <Heading title={"Manifest E-way Bill"} />
        <div className="hub-manifest-container p-2">
          <Form
            form={form}
            onFinish={handleSubmit}
            name="accountBasicDetails"
            layout="vertical"
            initialValues={{
              transDocDate: formattedToday,
              manifestId: params?.id,
              driverName: history?.state?.driverName || "",
              vehicleNo: history?.state?.vehicleNumber || "",
            }}
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}
          >
            {tableData?.length ? (
              <>
                <Row justify="center" gutter={16} className="m-1">
                  <Col span={8} sm={24} md={8} lg={8} xs={24}>
                    <Form.Item
                      // initialValue={"bottom"}
                      name="transDocDate"
                      label="Date"
                      rules={[
                        {
                          required: false,
                          message: "Select date",
                        },
                      ]}
                    >
                      <Input placeholder="Date" disabled />
                    </Form.Item>
                  </Col>

                  <Col span={8} sm={24} md={8} lg={8} xs={24}>
                    <Form.Item
                      name="manifestId"
                      label="Manifest Id"
                      value={params?.id}
                      rules={[
                        {
                          required: false,
                          message: "Enter manifest Id",
                        },
                      ]}
                    >
                      <Input disabled placeholder="Enter Manifest Id" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="center" gutter={16} className="m-1">
                  <Col span={8} sm={24} md={8} lg={8} xs={24}>
                    <Form.Item
                      name="driverName"
                      label="Driver name"
                      rules={[
                        {
                          required: true,
                          message: null,
                        },
                        () => ({
                          validator(rule, value = "") {
                            if (value.length < 3) {
                              return Promise.reject("Please enter name");
                            }
                            if (value.substring(1, 0) === " ") {
                              return Promise.reject("Please enter valid name");
                            } else {
                              return Promise.resolve();
                            }
                          },
                          validateTrigger: "onSubmit",
                        }),
                      ]}
                    >
                      <Input
                        maxLength={30}
                        placeholder="Enter driver name"
                        onKeyPress={(event) => {
                          if (checkAlphabets(event)) {
                            event.preventDefault();
                          }
                        }}
                        onInput={(e) =>
                          (e.target.value = firstLetterCapitalise(e))
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8} sm={24} md={8} lg={8} xs={24}>
                    <Form.Item
                      name="vehicleNo"
                      label={"Vehicle number"}
                      rules={[
                        {
                          required: true,
                          message: "Enter vehicle number",
                        },
                      ]}
                      onKeyPress={(event) => {
                        if (checkSpecialCharacter(event) || checkSpace(event)) {
                          event.preventDefault();
                        }
                      }}
                    >
                      <Input
                        style={{ textTransform: "uppercase" }}
                        placeholder="Example:KA32F2622"
                        // disabled
                        onBlur={(e) => {
                          form.setFieldValue(
                            "vehicleNo",
                            e.target.value.toUpperCase()
                          );
                        }}
                        maxLength={12}
                        onChange={(e) => setVehicleNum(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="end">
                  <Button
                    className="save-btn"
                    htmlType="submit"
                    // onClick={form.submit}
                  >
                    CONSOLIDATE UPDATE
                  </Button>
                </Row>
              </>
            ) : (
              <Row justify="end">
                <h2 style={{ margin: "auto" }}>No E-way bills present</h2>
              </Row>
            )}
          </Form>
        </div>

        <div className="p-1 hub-eway-container">
          <Input
            placeholder="Search by AWB, Eway number"
            bordered
            onChange={onSearch}
            style={{
              borderRadius: "5px",
              height: "40px",
              maxWidth: "250px",
              marginBottom: "20px",
            }}
            suffix={<img src={search_icon} />}
          />
          <Table
            // onChange={onSelectChange}
            columns={columns}
            onChange={handleChange}
            scroll={{ x: 500 }}
            dataSource={inputSearchValue.length == 0 ? tableData : dataSource}
            // dataSource={[]}
            pagination={false}
            rowKey={(record) => record?.ewayBillNumber}
            rowSelection={{
              ...rowSelection,
            }}
          />
        </div>

        <Row justify="end mt-2">
          <Button
            className="save-btn"
            htmlType="submit"
            disabled={enableLockManifest}
            onClick={handleLockManifest}
          >
            LOCK MANIFEST
          </Button>
        </Row>
      </div>
    </Spin>
  );
};
export default CPWiseHubManifestEWayBill;
