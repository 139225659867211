/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import envUrls from "../../../config/env-urls";
import { useLocation } from "react-router-dom";
import Heading from "../../../components/Heading";
import { Card } from "antd";

const AllReports = () => {
  const location = useLocation();
  const { embeddedId, rsl } = location.state || {};
  const REACT_APP_ENV = process.env.REACT_APP_ENV || "dev";
  const dashboardId =embeddedId; // Default to HUB_ADMIN if embeddedId is not provided
  const guestTokenApi=envUrls[REACT_APP_ENV].API.reportGustTokenURL;
  
  useEffect(() => {

    const initDashboard = async () => {
      try {
        await refreshTokens();
      } catch (error) {
        console.error("Error initializing dashboard:", error);
      }
    };
    initDashboard();
    const tokenInterval = setInterval(refreshTokens, 4 * 60 * 1000);
    return () => clearInterval(tokenInterval);
  }, []);
  const refreshTokens = async () => {
    try {
      const guestToken = await fetchGuestToken();
      embedDashboardWithToken(guestToken);
    } catch (error) {
      console.error("Error refreshing tokens:", error);
    }
  };
  const fetchGuestToken = async () => {
    try {
      const data = {
        username: envUrls[REACT_APP_ENV].auth.datazip_userName,
        password: envUrls[REACT_APP_ENV].auth.datazip_password,
        user: {
          username: envUrls[REACT_APP_ENV].auth.datazip_userName,
        },
        resources: [
          {
            type: "dashboard",
            id: dashboardId,
          },
        ],
        rls: rsl,
      };
  
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: guestTokenApi, // Ensure URL is correct
        headers: { 
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };
      const response = await axios.request(config);
      return response?.data?.token; // Return data for further use
    } catch (error) {
      console.error("Error fetching guest token:", error);
      throw error; // Propagate error for handling
    }
  };
  const embedDashboardWithToken = (token) => {
    embedDashboard({
      id: dashboardId,
      supersetDomain: "https://app.datazip.delcaper.com",
      mountPoint: document.getElementById("superset-container"),
      fetchGuestToken: () => token,
      dashboardUiConfig: {
        hideTitle: true,
        hideTab: false,
        hideChartControls: false,
        hideTableTitles: true,
        chartHeight: 800,
      },
    });
    let iframe = document.querySelector("#superset-container>iframe");
    iframe.style.height = "75vh";
    iframe.style.width = "90vw"; 
  };
  return (

    <>
      <Card className="hub-cp-container" style={{ background: "transparent", boxShadow: "none", border: "none" }}>
        <Heading/>
        <div style={{ paddingTop: "15px" }}>
          <div id="superset-container"></div>
        </div>
      </Card>
    </>
  );
};


export default AllReports;
