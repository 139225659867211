/* eslint-disable indent */
/* eslint-disable no-undef */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Table,
  Col,
  Row,
  Select,
  Form,
  Spin,
  Input,
  Button,
  message,
} from "antd";
import axios from "axios";
import { APIs } from "../../../../utils/worker";
import { capitalize, capitaliseWithHyphen } from "../../../../utils";
import { LoadScript } from "@react-google-maps/api";
import { getPremiseId } from "../../../../utils/worker";
import constants from "../../../../constants/constants";
import { getHubListWithMode } from "./index.jsx";
const { ROLES } = constants;
const { HO_ADMIN } = ROLES;

let userData = JSON.parse(localStorage.getItem("userinfo"));
import "./style.scss";

const EditVehicleRoute = ({ handleEditRoute, handleCloseModal, primaryMode,editRouteData }) => {
  console.log("editRouteData",editRouteData);
  const origin = editRouteData[0]?.route?.find((item) => item.position === 0);
  const transit = editRouteData[0]?.route?.filter((item) => item?.position !== 0 && item?.position !==  editRouteData[0]?.route?.length - 1); 
  const destination = editRouteData[0]?.route?.find((item) => item.position ===  editRouteData[0]?.route?.length - 1);
  console.log("origin",origin);
  console.log("transit",transit,transit.length);
  console.log("destination",destination);
  
  const formLayout = "vertical";
  const [form] = Form.useForm();
  const transitMode = [
    { label: "Surface", value: "SURFACE" },
    { label: "Air", value: "AIR" },
    { label: "Both", value: "BOTH" },
  ];
  const [transitForm, setTransitForm] = useState([]);
  const [hubDetails, setHubDetails] = useState([]);
  const [transitHubDetails, setTransitHubDetails] = useState([]);
  const [hubDetailsParent, setHubDetailsParent] = useState([]);
  const [originHub, setOriginHub] = useState("");
  const [originLatLng, setOriginLatLng] = useState({
    latitude: "",
    longitude: "",
  });
  const [destLatLng, setDestLatLng] = useState({ latitude: "", longitude: "" });
  const [destinationHub, setDestinationHub] = useState("");
  const [destinationTime, setDestinationTime] = useState("");
  const [tokenData, setToken] = useState("");
  const [transitValue, settransitValue] = useState(0);
  const [test, setTest] = useState(false);
  const [roles, setRoles] = useState();

  useEffect(() => {
    setRoles(JSON.parse(localStorage.getItem("userinfo")));
  }, []);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userinfo"));
    getHubDetails(userData.accessToken);
    setToken(userData.accessToken);
  }, []);
  useEffect(() => {
    changeTransitHubs();
  }, [transitValue, destinationHub]);

  const changeTransitHubs = () => {
    setTransitHubDetails(
      hubDetails.filter(
        (e) => e.value !== originHub && e.value !== destinationHub
      )
    );
  };
  const getHubDetails = async (token) => {
    let response = await axios.get(
      APIs.baseURL + "/premise-service/v1/premises",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let temp = [];
    response.data.map((item) => {
      temp.push({
        label: capitaliseWithHyphen(item?.premiseName),
        value: item?.premiseId,
        latitude: item?.latitude,
        longitude: item?.longitude,
      });
    });
    setHubDetails(temp);
    setHubDetailsParent(temp);
    roles && roles?.roles?.includes(HO_ADMIN)
      ?""
      : setOriginHubDetails(temp, origin?.premise);
  };

  const setOriginHubDetails = (a, b) => {
    let foundData = a?.find((item) => item?.value == b);
     if (foundData) {
          form.setFieldValue("destinationHub",destination?.premise);
          form.setFieldValue("originHub",foundData?.value);
          setOriginHub(foundData?.value);
          setDestinationHub(destination?.premise);
          setOriginLatLng({
            latitude: foundData?.latitude,
            longitude: foundData?.longitude,
          });
          form.setFieldValue("noOfTransit",editRouteData[0]?.route?.length - 2);
          form.setFieldValue("destinationTransitDistance", destination?.distance + " km");
          form.setFieldValue("destinationTransitTime", timeConvert(destination?.transitTime));
          form.setFieldValue("destinationTransitMode",capitalize(destination?.travelBy));
          if (editRouteData[0]?.route?.length > 2) {
            
            let temp = [...editRouteData[0].route];
            let temp2 = [...editRouteData[0].route];
            let transitForm_ = [];
            temp.pop();
            temp.shift();   
            for (let i = 0; i < temp.length; i++) {
              transitForm_.push({
                hub:temp[i]?.hubName,
                time: i==0 ? temp2[0].transitTime:temp[i-1]?.transitTime,
                mode: i==0 ? temp2[0]?.travelBy:temp[i-1]?.travelBy,
                distance:i==0 ? temp2[0].distance :temp[i-1]?.distance,
              });
            }
            setTransitForm([...transitForm_]);
          } else {
            form.setFieldValue(
              "destinationTransitMode",
              capitalize(editRouteData[0]?.routes[0]?.travelBy)
            );
          }
          // handleSelectChange(editRouteData.destinationHubId, "destinationHub");
        }
  };
  useEffect(() => {
    if (transitForm?.length > 0) {
      let temp = [...transitForm];
      for (let i = 0; i < transitForm?.length; i++) {
        form.setFieldValue("transitHub" + i, capitalize(temp[i]?.hub));
        form.setFieldValue("transitMode" + i, capitalize(temp[i]?.mode));
        form.setFieldValue("transitTime" + i, timeConvert(temp[i]?.time));
        form.setFieldValue("transitDistance" + i, temp[i]?.distance + " km");
      }
    }
  }, [transitForm]);
  const onSearch = (e) => {
    // console.log(e);
  };
  const formProps = {
    name: "address",
    className: "register-form",
    onFinish: (values) =>
      handleEditRoute(values, transitForm, destinationTime,editRouteData[0]?.routeId),
    autoComplete: "off",
  };

  const handleSelectChange = (value, name, x) => {
    let findLatLng = hubDetailsParent?.find((it) => it?.value === value);
    if (name === "destinationHub") {
      setDestinationHub(value);
      setDestLatLng({
        latitude: findLatLng?.latitude,
        longitude: findLatLng?.longitude,
      });
      fetchRouteServiceMaster(originHub,value);
      
    } else if (name === "originHub") {
      form.setFieldValue("destinationHub", null);
      form.setFieldValue("destinationTransitTime", null);
      setDestinationTime("");
      form.setFieldValue("totalTAT", null);
      setOriginHub(value);
      setOriginLatLng({
        latitude: findLatLng?.latitude,
        longitude: findLatLng?.longitude,
      });
      for (let i = 0; i < transitForm?.length; i++) {
        form.setFieldValue("transitHub" + i, null);
        form.setFieldValue("transitTime" + i, null);
      }
    }
  };
  const changeTransitValue = (e) => {
    for (let i = 0; i < transitForm?.length; i++) {
      form.setFieldValue("transitHub" + i, null);
      form.setFieldValue("transitTime" + i, null);
      form.setFieldValue("transitDistance" + i, null);
      form.setFieldValue("destinationTransitTime", null);
      form.setFieldValue("destinationTransitDistance", null);
      form.setFieldValue("totalTAT", null);
    }
    let value = e.target.value;
    if (value > 7) {
      settransitValue(7);
    } else {
      settransitValue(value);
      let transitForm_ = [];
      for (let i = 0; i < value; i++) {
        transitForm_.push({
          hub: "",
          time: "",
          mode: primaryMode ===  "SURFACE" ? "SURFACE" : "AIR",
          distance: "",
          final_distance: "",
          final_time: "",
        });
      }
      setTransitForm([...transitForm_]);
      setTest(!test);
    }
    if (value === "" || value == "0") {
      handleSelectChange(destinationHub, "destinationHub", "x");
    }
  };
  function timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) " + rminutes + " minute(s)";
  }
  const transitData = () => {
    let design = [];
    transitForm?.map((item, index) => {
      let random = Math.random();
      design.push(
        <Row className="global-form-row" gutter={20} key={index}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              name={"transitHub" + index}
              label={"Transit " + (index + 1)}
              // initialValue={item.hub ? item.hub : "Select transit hub"}
              rules={[
                {
                  required: true,
                  message: "Please select transit hub",
                },
              ]}
            >
              <Select
                showSearch
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select transit hub"
                options={transitHubDetails}
                onChange={(data) =>
                  // changeTransit(data, index, "hub", "transitHub" + index)
                  changeTransitNew(data, index, "hub", "transitHub" + index)
                }
                value={item.hub}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              name={`transitMode${index}`}
              label={
                index === 0
                  ? "Origin to T" + (index + 1) + " Mode"
                  : "T" + index + " to " + "T" + (index + 1) + " Mode"
              }
              initialValue={primaryMode}
              rules={[
                {
                  required: true,
                  message: "Please select mode",
                },
              ]}
            >
              <Select
                placeholder="Select mode"
                disabled={primaryMode === "AIR" ? false : true}
                options={transitMode}
                onChange={(data) =>
                  // changeTransit(data, index, "mode", `transitMode${index}`)
                  changeTransitNew(data, index, "mode", `transitMode${index}`)
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              name={`transitDistance${index}`}
              label={
                index === 0
                  ? "Origin to T" + (index + 1) + " Distance"
                  : "T" + index + " to " + "T" + (index + 1) + " Distance"
              }
              initialValue={item.distance}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              name={`transitTime${index}`}
              label={
                index === 0
                  ? "Origin to T" + (index + 1) + " TAT"
                  : "T" + index + " to " + "T" + (index + 1) + " TAT"
              }
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select time",
              //   },
              // ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      );
    });
    return design;
  };

  const fetchRouteServiceMaster = async(sourceId,destinationId) => {
    let payload = {
      "source":sourceId,
      "destination":destinationId,
      "type":"HUB",
      "mode": "SURFACE"
    };
    
    try {
      let response = await axios.post(
        `${APIs.tatURL}`,
        payload,
        {
          headers: {
            "Authorization": userData?.accessToken,
            //token: token.split("Bearer ")[1],
            "clientname" : "HUBOPS_AUTH_KEY",
            "authtype" : "x-api-key",
            "x-api-key" : "61757468746f6b656e666f726875626f7073"

          },
        }
      );
      if (response?.status) {
        let distance = response?.data?.data?.totalDistance;
        let time = +response?.data?.data.totalTime / 40;
        let finalTime = (time / 4 + time).toFixed(1);
        let finalMinute = finalTime * 60;
        let actualTime = timeConvert(finalMinute);
        

        form.setFieldValue("destinationTransitDistance", distance);
        form.setFieldValue("destinationTransitTime", actualTime);
        setDestinationTime(finalTime);
            for (let i = 0; i < transitForm.length; i++) {
              form.setFieldValue("transitHub" + i, null);
              form.setFieldValue("transitTime" + i, null);
              form.setFieldValue("totalTAT", null);
            }
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
      
    }
  };
  const changeTransitNew = async (data, index, name, nameVal) => {
    let findLatLng = hubDetailsParent.find((it) => it.value === data);
    let sourceHub = 0;
    if(index==0){
      sourceHub = originHub;
    }
    else{
      sourceHub = transitForm[index-1].hub;
    }

    let payload = {
      "source":sourceHub,
      "destination":data,
      "type":"HUB",
      "mode": "SURFACE"
    };

    sourceHub = data;

    try {
      let response = await axios.post(
        `${APIs.tatURL}`,
        payload,
        {
          headers: {
            "Authorization": userData?.accessToken,
            //token: token.split("Bearer ")[1],
            "clientname" : "HUBOPS_AUTH_KEY",
            "authtype" : "x-api-key",
            "x-api-key" : "61757468746f6b656e666f726875626f7073"

          },
        }
      );
      if (response?.status) {
        let distance = response?.data?.data?.totalDistance;
        let time = +response?.data?.data.totalTime / 40;
        let finalTime = (time / 4 + time).toFixed(1);
        let finalMinute = finalTime * 60;
        let actualTime = timeConvert(finalMinute);
        let transitForm_ = transitForm;
        form.setFieldsValue(nameVal, data);
        form.setFieldValue("transitDistance" + index, distance);
        form.setFieldValue("transitTime" + index, actualTime);
         transitForm_[index] = {
            ...transitForm_[index],
            [name]: data,
            distance: distance,
            time: finalTime,
            latitude: findLatLng?.latitude,
            longitude: findLatLng?.longitude,
            actualTime: actualTime,
            final_time: finalMinute,
          
          };
        
        setTransitForm([...transitForm_]);
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
      
    }
    if (index === transitForm.length - 1) {
      let payload = {
        "source":sourceHub,
        "destination":destinationHub,
        "type":"HUB",
        "mode": "SURFACE"
      };
      try {
        let response = await axios.post(
          `${APIs.tatURL}`,
          payload,
          {
            headers: {
              "Authorization": userData?.accessToken,
              //token: token.split("Bearer ")[1],
              "clientname" : "HUBOPS_AUTH_KEY",
              "authtype" : "x-api-key",
              "x-api-key" : "61757468746f6b656e666f726875626f7073"
  
            },
          }
        );
        if (response?.status) {
          let distance = response?.data?.data?.totalDistance;
          let time = +response?.data?.data.totalTime / 40;
            let finalTime = (time / 4 + time).toFixed(1);
            let finalMinute = finalTime * 60;
            let actualTime = timeConvert(finalMinute);
            form.setFieldValue("destinationTransitDistance", distance);
            form.setFieldValue("destinationTransitTime", actualTime);
            setDestinationTime(finalTime);
        }
      } catch (error) {
        console.log(error);
        message.error(error.response.data.message);
      }
    }
  };
  return (
    <div>
      <Spin spinning={false}>
        <Form
          {...formProps}
          form={form}
          layout={formLayout}
          className="route-table global-form"
        >
          <Row className={"global-form-row route-main"} gutter={20}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name="originHub"
                label="Origin Hub"
                rules={[
                  {
                    required: true,
                    message: "Please select origin hub",
                  },
                ]}
              >
                <Select
                  showSearch
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select origin hub"
                  options={hubDetails}
                  onChange={(value) => handleSelectChange(value, "originHub")

                  }
                  disabled={
                    roles && roles?.roles?.includes(HO_ADMIN) ? false : true
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name="destinationHub"
                label="Destination Hub"
                rules={[
                  {
                    required: true,
                    message: "Please select destination hub",
                  },
                ]}
              >
                <Select
                  showSearch
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select destination hub"
                  options={hubDetails}
                  onChange={(value) =>
                    handleSelectChange(value, "destinationHub")
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}></Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name="primaryMode"
                label="Primary mode of travel"
                rules={[
                  {
                    required: true,
                    message: "Please select mode",
                  },
                ]}
                initialValue={primaryMode}
              >
                <Select
                  className="disable-input"
                  disabled
                  placeholder="Select mode"
                  options={transitMode}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={3} lg={3} xl={3}>
              <Form.Item name="noOfTransit" label="Add Transit">
                <Input
                  placeholder="0"
                  onChange={changeTransitValue}
                  value={transitValue}
                  onKeyPress={(e) => {
                    if (/[^0-7]/.test(e.key) || e.target.value.length === 1) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={13}></Col>
          </Row>
          {transitData()}
          <Row gutter={20} className="global-form-row">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item name="destinationHub" label="Destination Hub">
                <Select
                  placeholder="Select destination hub"
                  options={hubDetails}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name={`destinationTransitMode`}
                label={
                  transitForm.length > 0
                    ? "T" + transitForm.length + " to Destination Mode"
                    : "Origin to Destination Mode"
                }
                rules={[
                  {
                    required: true,
                    message: "Please select mode",
                  },
                ]}
                initialValue={primaryMode}
              >
                <Select
                  placeholder="Select mode"
                  disabled={primaryMode === "SURFACE" && true}
                  // disabled
                  options={transitMode}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name={`destinationTransitDistance`}
                label={
                  transitForm.length > 0
                    ? "T" + transitForm.length + " to Destination Distance"
                    : "Origin to Destination Distance"
                }
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name={`destinationTransitTime`}
                label={
                  transitForm.length > 0
                    ? "T" + transitForm.length + " to Destination TAT (in Hour)"
                    : "Origin to Destination TAT (in hour)"
                }
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item name="totalTAT" label="Total TAT" initialValue={0}>
                <Input disabled />
              </Form.Item>
            </Col> */}
          </Row>
          <hr className="divider mt-3" />
          <div className="d-flex flex-end" style={{ padding: "1rem 0 0 0" }}>
            <Button className="cancel-btn" onClick={handleCloseModal}>
              CANCEL
            </Button>
            <Button htmlType="submit" className="save-btn ml-1">
              SAVE
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default EditVehicleRoute;

