/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Input, Button, List, Card, message, Modal } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import "./style.scss";
import urls from "../../../api/urls";
import NormalAPI from "../../../api";
import Heading from "../../../components/Heading";

const {
    GET_ALL_PINCODE_HUB_WISE_MAPPING,
    DELETE_PINCODE_HUB_WISE_MAPPING,
    ADD_PINCODE_HUB_WISE_MAPPING
} = urls;
const HubToPinCodeMapping = () => {
    const [pincode, setPincode] = useState("");
    const [pincodes, setPincodes] = useState([]);
    const isFetched = useRef(false);
    const [roles, setRoles] = useState();
    const { confirm } = Modal;

    useEffect(() => {
        setRoles(JSON.parse(localStorage.getItem("userinfo")));
        if (!isFetched.current) {
            getALL_PINCODE_MAPPED_WITH_HUB();
            isFetched.current = true;
        }
    }, []);
    const handleSave = () => {
        if (!pincode.trim()) return;
        const formattedPincode = pincode.trim();
        const formattedPincodes = pincodes.map(String);
        if (!formattedPincodes.includes(formattedPincode)) {
            addPincodeToHub();
        } else {
            message.error("Pincode already exists.");
        }
    };
    const showDeleteConfirm = (pin) => {
        let timer = 5; // 5 seconds
        let interval;
    
        const modal = confirm({
            title: "Are you sure you want to delete this pincode?",
            icon: <ExclamationCircleOutlined />,
            content: `Pincode: ${pin}`,
            okText: `Yes, Delete (${timer}s)`, // Initial text with countdown
            okType: "danger",
            cancelText: "Cancel",
            okButtonProps: { disabled: true }, // Disable initially
            onOk() {
                handleDelete(pin);
            },
            onCancel() {
                clearInterval(interval); // Clear timer if canceled
            },
        });
    
        // Start countdown
        interval = setInterval(() => {
            timer -= 1;
            modal.update({
                okText: `Yes, Delete (${timer}s)`,
            });
    
            if (timer <= 0) {
                clearInterval(interval);
                modal.update({
                    okText: "Yes, Delete",
                    okButtonProps: { disabled: false }, // Enable button after 100 sec
                });
            }
        }, 1000);
    };
    
    const handleDelete = (pin) => {
        deletePincodeHubWise(pin);
    };

    const getALL_PINCODE_MAPPED_WITH_HUB = async (params) => {
        try {
            const response = await NormalAPI.Api(GET_ALL_PINCODE_HUB_WISE_MAPPING).getWithPremiseIdHeader();
            if (response?.status) {
                const pincodeData = response?.response?.data?.pincodes || [];
                setPincodes(pincodeData);
            } else {
                message.error(response?.response?.data?.message);
            }
        } catch (error) {
            message.error(error?.message);
        }
    };
    const addPincodeToHub = async () => {
        try {
            let payload = {
                premiseId: roles?.premiseId,
                pincodes: [pincode],
            };
    
            const response = await NormalAPI.Api(ADD_PINCODE_HUB_WISE_MAPPING).postIdHeader(payload);
    
            if (response?.status) {
                setPincode(""); // Clear input
                message.success(response?.response?.data?.message);
                getALL_PINCODE_MAPPED_WITH_HUB(); // Refresh list
            } else {
                message.error(response?.response?.data?.message);
            }
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
    };
    
    const deletePincodeHubWise = async (data) => {
        try {
            let request = {
                premiseId: roles?.premiseId,
                pincodes: [data],
            };
    
            const response = await NormalAPI.Api(DELETE_PINCODE_HUB_WISE_MAPPING).deleteIdHeader(request);
    
            if (response?.status) {
                message.success(response?.response?.data?.message);
                getALL_PINCODE_MAPPED_WITH_HUB(); // Refresh list
            } else {
                message.error(response?.response?.data?.message);
            }
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
    };
    
    return (
        <Card className="hub-pincode-container">
            <Heading
                title="Hub to Pincode Mapping"
            />
            <div className="hub-pincode-wrapper">
                {/* Add Pincode Card */}
                <Card title="Add Pincode" className="hub-pincode-card">
                    <div className="pincode-input-container">
                        <Input
                            type="number"
                            placeholder="Enter 6-digit Pincode"
                            value={pincode}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,6}$/.test(value)) {
                                    setPincode(value);
                                }
                            }}
                            maxLength={6} // Prevents input beyond 6 digits
                            minLength={6}
                            onPressEnter={handleSave}
                            className="pincode-input"
                        />
                        <Button type="primary" onClick={handleSave} disabled={pincode.trim().length !== 6}>
                            Save
                        </Button>
                    </div>
                </Card>

                {/* Mapped Pincodes Card */}
                <Card title="Mapped Pincodes" className="hub-pincode-card">
                    <div className="pincode-list">
                        {pincodes.length > 0 ? (
                            pincodes.map((pin) => (
                                <div key={pin} className="pincode-item">
                                    {pin}
                                    <DeleteOutlined onClick={() => showDeleteConfirm(pin)} className="delete-icon" />
                                </div>
                            ))
                        ) : (
                            <p className="empty-text">No Pincodes Added</p>
                        )}
                    </div>
                </Card>
            </div>
        </Card>
    );
};

export default HubToPinCodeMapping;
