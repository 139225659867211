/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Input, Select, message, Spin } from "antd";
import API from "../../../api";
import urls from "../../../api/urls";
import {
  capitaliseWithHyphen,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../utils";
import no_shipment from "../../../assests/images/middleMile/no-shipment.svg";
import { ReactComponent as Scan_check } from "../../../assests/images/SVG/check.svg";
import bag from "../../../assests/images/middleMile/bag.svg";
import white_bag_ from "../../../assests/images/middleMile/white-bag-new.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
const {
  DAMAGE_REASON_LIST,
  POST_DAMAGED_BAG,
  POST_DAMAGED_BAG_DATA,
  POST_LEFT_BAG_DATA,
} = urls;

export function damageBagModal(showDamageBagModal, setShowDamageBagModal) {
  const [loading, setLoading] = useState(false);
  const [damageReasonList, setDamageReasonList] = useState([]);
  const [damageReason, setDamageReason] = useState(null);
  const [otherReason, setOtherReason] = useState("");
  const [oldBagNum, setOldBagNum] = useState("");
  const [bagDetails, setBagDetails] = useState(null);
  const [currentScanNum, setcurrentScanNum] = useState(null);
  const [barCodeList, setBarCodeList] = useState([]);
  const [leftBagData, setLeftBagData] = useState([]);
  const [newBagNum, setNewBagNum] = useState("");
  const [showLeftDataModal, setShowLeftDataModal] = useState(false);
  const [remark, setRemark] = useState("");
  const { TextArea } = Input;

  useEffect(() => {
    if (currentScanNum) {
      if (!barCodeList.find((e) => e.barcodeVal == currentScanNum)) {
        let data = bagDetails;
        let foundData = data?.bagContents?.find(
          (item) => item.barcodeNo == currentScanNum
        );
        if (foundData) {
          scanBarCode(foundData?.weight);
        } else {
          message.error("Shipment not found.");
        }
      }
    }
  }, [currentScanNum]);

  const scanBarCode = async (weight) => {
    if (currentScanNum) {
      let bagData = bagDetails;
      let temp = [];
      temp.push({ barcodeVal: currentScanNum, shipmentWeight: weight });
      setBarCodeList([...barCodeList, ...temp]);
      bagData?.bagContents?.splice(
        bagData?.bagContents?.findIndex((e) => e.barcodeNo === currentScanNum),
        1
      );
      setBagDetails(bagData);
    }
  };

  useEffect(() => {
    getAllDamageReason();
  }, []);

  const getAllDamageReason = async () => {
    let { response } = await API.Api(DAMAGE_REASON_LIST).getWithAllHeaders();
    if (response?.status) {
      let data = response?.data?.sort(
        (a, b) => a.damagedBagResonId - b.damagedBagResonId
      );
      setDamageReasonList(data);
    }
  };

  const changeDamageReason = (e) => {
    setDamageReason(e);
  };

  const submitDamageReason = async () => {
    if (oldBagNum && damageReason && newBagNum) {
      setLoading(true);
      let payload = {
        bagBarcodeNo: oldBagNum,
        damageReason: damageReason !== "Others" ? damageReason : otherReason,
      };
      let response = await API.Api(POST_DAMAGED_BAG).putIdHeader(payload);
      if (response?.status) {
        setLoading(false);
        setBagDetails(response?.response?.data);
      } else {
        setLoading(false);
        message.error(response?.response?.data?.message || "Network error.");
      }
    } else {
      message.error("Please fill all the fields.");
    }
  };
  const finaliseBag = async () => {
    let payload = {
      damagedBagId: bagDetails?.bagBarcodeNo,
      awbNumbers: barCodeList?.map((e) => e.barcodeVal),
    };
    let bagContentData = bagDetails.bagContents;
    if (bagDetails.bagContents.length > 0) {
      setShowLeftDataModal(true);
      let leftData = bagContentData.filter(
        (item) => !payload?.awbNumbers.includes(item.barcodeNo)
      );
      setLeftBagData(leftData);
    } else {
      let response = await API.Api(
        POST_DAMAGED_BAG_DATA + newBagNum
      ).postIdHeader(payload);
      if (response?.status) {
        setShowDamageBagModal(false);
        clearModalData();
      } else {
        message.error(response?.response?.data?.message || "Network error.");
      }
    }
  };

  const finaliseWithoutData = async () => {
    setLoading(true);
    let payload = {
      damagedShipments: leftBagData?.map((e) => e.barcodeNo),
      remark: remark,
    };
    let response = await API.Api(POST_LEFT_BAG_DATA).putIdHeader(payload);
    setShowLeftDataModal(false);
    if (response?.status) {
      setLoading(false);
      let payload1 = {
        damagedBagId: bagDetails?.bagBarcodeNo,
        awbNumbers: barCodeList?.map((e) => e.barcodeVal),
      };
      let response1 = await API.Api(
        POST_DAMAGED_BAG_DATA + newBagNum
      ).postIdHeader(payload1);
      if (response1?.status) {
        setLoading(false);
        setShowDamageBagModal(false);
        clearModalData();
      } else {
        setLoading(false);
        message.error(response1?.response?.data?.message);
      }
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message);
    }
  };

  const clearModalData = () => {
    setShowDamageBagModal(false);
    setDamageReason(null);
    setOtherReason("");
    setOldBagNum("");
    setBagDetails(null);
    setcurrentScanNum(null);
    setBarCodeList([]);
    setLeftBagData([]);
    setNewBagNum("");
    setRemark("");
    setShowLeftDataModal(false);
  };

  return (
    <Modal
      title={"Damage Bag"}
      open={showDamageBagModal}
      width={750}
      footer={null}
      className="create-bag"
      style={{ top: "200px !important" }}
      onCancel={() => {
        clearModalData();
      }}
      maskClosable={false}>
      <Spin
        spinning={loading}
        size="large"
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }>
        <Row>
          <Col span={10}>
            <Input
              className="input-without-form"
              placeholder="Old Bag number"
              autoComplete="off"
              value={oldBagNum}
              onKeyPress={(event) => {
                if (
                  checkNumbervalue(event) ||
                  checkSpace(event) ||
                  checkSpecialCharacter(event)
                ) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => setOldBagNum(e.target.value)}
            />
          </Col>
          <Col span={10}>
            <Input
              className="input-without-form"
              placeholder="New Bag number"
              autoComplete="off"
              value={newBagNum}
              onKeyPress={(event) => {
                if (
                  checkNumbervalue(event) ||
                  checkSpace(event) ||
                  checkSpecialCharacter(event)
                ) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => setNewBagNum(e.target.value)}
            />
          </Col>
          <Col span={10} className="mt-1">
            <Select
              className="select-without-form"
              placeholder="Select reason"
              options={damageReasonList?.map((item) => {
                return {
                  label: item?.damageReason,
                  value: item?.damageReason,
                };
              })}
              onChange={(e) => changeDamageReason(e)}
              value={damageReason}
            />
          </Col>
          {damageReason === "Others" && (
            <Col span={10} className="mt-1">
              <TextArea
                placeholder="Enter reamrk"
                rows={3}
                style={{ width: "260px", borderRadius: "5px" }}
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
              />
            </Col>
          )}
          <Col span={damageReason === "Others" ? 2 : 10} className="mt-1">
            <Button className="save-btn" onClick={() => submitDamageReason()}>
              SUBMIT
            </Button>
          </Col>
        </Row>
        {bagDetails && (
          <Row
            style={{
              border: "1px solid #ddd",
              borderRadius: "5px",
              marginTop: "5px",
              padding: "10px",
            }}>
            <Col span={5}>
              <span>
                Product:{" "}
                <strong>{capitaliseWithHyphen(bagDetails?.mode)}</strong>
              </span>
            </Col>
            <Col span={5}>
              <span>
                Content Type:{" "}
                <strong>{capitaliseWithHyphen(bagDetails?.contentType)}</strong>
              </span>
            </Col>
            <Col span={5}>
              <span>
                Mode:{" "}
                <strong>{capitaliseWithHyphen(bagDetails?.travelBy)}</strong>
              </span>
            </Col>
            <Col span={8}>
              <span>
                Destination:{" "}
                <strong>
                  {capitaliseWithHyphen(bagDetails?.destinationName)}
                </strong>
              </span>
            </Col>
            <Col span={24}>
              <Input
                placeholder="Enter AWB number to scan"
                style={{ width: "260px", marginLeft: "10px" }}
                onKeyPress={(event) => {
                  if (
                    // checkNumbervalue(event) ||
                    checkSpace(event) ||
                    checkSpecialCharacter(event)
                  ) {
                    event.preventDefault();
                  }
                }}
                onPressEnter={(e) => {
                  // eslint-disable-next-line no-constant-condition
                  if (e.key == "Enter") {
                    setcurrentScanNum(e.target.value);
                  }
                }}
              />
            </Col>
            <Col
              span={10}
              className="bag-design"
              style={{ marginLeft: "10px" }}>
              <p
                style={{
                  borderBottom: "1px solid #dddddd",
                  padding: "0.5rem",
                }}
                className="text-center">
                Eligible Contents
              </p>
              <div style={{ height: "230px", overflow: "auto" }}>
                <table
                  style={{
                    width: "100%",
                  }}>
                  <thead
                    style={{
                      borderBottom: "1px solid #ddd",
                    }}>
                    <th style={{ textAlign: "center" }}>AWB No.</th>
                    <th style={{ textAlign: "left" }}>Weight (kg)</th>
                  </thead>
                  <tbody>
                    {bagDetails?.bagContents?.map((item, i) => (
                      <tr
                        key={i}
                        className="ml-1"
                        style={{
                          borderBottom: "1px solid #ddd",
                        }}>
                        <td style={{ padding: "0 1rem" }}>{item.barcodeNo}</td>
                        <td>{item?.weight}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
            <Col span={10}>
              <div className="bag-design">
                <div
                  style={{
                    background: bagDetails?.bagColour,
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}>
                  <p
                    className="space-between align-center"
                    style={{
                      borderBottom: "1px solid #dddddd",
                    }}>
                    {bagDetails?.bagColour == "#fff" ? (
                      <img src={white_bag_} className="ml-1" />
                    ) : (
                      <img src={bag} className="ml-1" />
                    )}
                    <span
                      style={{
                        padding: "0.5rem",
                        display: "block",
                        color:
                          bagDetails?.bagColour == "#fff" ? "#444444" : "#fff",
                        textTransform: "capitalize",
                      }}>
                      {bagDetails?.bagColour == "#70C36E"
                        ? "Green Bag"
                        : bagDetails?.bagColour == "#2E7FE1"
                        ? "Blue Bag"
                        : bagDetails?.bagColour == "#D2DD3F"
                        ? "Yellow Bag"
                        : bagDetails?.bagColour == "#FFAB02"
                        ? "Orange Bag"
                        : "White Bag"}
                    </span>
                    <Input
                      style={{
                        background: "#F7F7F7",
                        borderRadius: "5px",
                        width: "50%",
                        margin: "5px",
                      }}
                      value={newBagNum}
                      disabled={
                        bagDetails?.status == "FINALISED" ||
                        bagDetails?.bagContents.length > 0
                      }
                    />
                  </p>
                </div>

                {barCodeList?.length > 0 ? (
                  <div style={{ height: "250px" }}>
                    <div
                      style={{
                        height: "75%",
                        overflowX: "scroll",
                      }}>
                      {barCodeList?.map((data, contentIndex) => (
                        <div
                          className="space-between"
                          key={contentIndex}
                          style={{
                            borderBottom: "0.5px solid #ddd",
                          }}>
                          <div className="ml-1">
                            {data.barcodeVal} &nbsp;
                            <Scan_check />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        background: "#F3F3F3",
                        padding: "0.4rem",
                      }}>
                      Shipment Weight:{" "}
                      <span>
                        {(
                          Math.round(
                            barCodeList?.reduce(
                              (a, b) => a + b?.shipmentWeight,
                              0
                            ) * 100
                          ) / 100
                        ).toFixed(2)}
                      </span>
                    </div>
                    {/* <div style={{ padding: "0.5rem 1rem" }}>
                      <span>Booking Weight</span>
                      <br />
                      <div className="space-between">
                        <Input
                          style={{
                            background: "#F7F7F7",
                            borderRadius: "5px",
                            width: "60%",
                          }}
                          value={finaliseWeight}
                          onChange={(e) => setFinaliseWeight(e.target.value)}
                        />
                      </div>
                    </div> */}
                    <div className="flex-end mr-1">
                      <Button
                        onClick={() => finaliseBag()}
                        className={
                          barCodeList?.length > 0
                            ? "save-btn ml-1"
                            : "btn-style ml-1"
                        }
                        disabled={barCodeList?.length === 0}>
                        FINALISE
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="center" style={{ marginTop: "25%" }}>
                    <img src={no_shipment} />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        )}
        <Modal
          title={"Damage Bag"}
          open={showLeftDataModal}
          width={500}
          footer={null}
          className="create-bag"
          style={{ top: "400px !important" }}
          onCancel={() => {
            setShowLeftDataModal(false);
          }}
          maskClosable={false}
          destroyOnClose>
          <>
            {leftBagData?.map((e, i) => (
              <p key={`key_${i}`} style={{ color: "orange" }}>
                {e.barcodeNo},{" "}
              </p>
            ))}
            <p>
              The above shipments are discarded from bag as they are not in an
              appropriate condition of delivery. These shipments can be found in
              Damaged/Missing shipment list.
            </p>
            <TextArea
              placeholder="Enter reason or remark"
              rows={3}
              span={10}
              style={{ width: "260px", borderRadius: "5px" }}
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            />
            <div className="flex-end p-1">
              <Button
                className="cancel-btn ml-1"
                onClick={() => setShowLeftDataModal(false)}>
                CANCEL
              </Button>
              <Button
                htmlType="submit"
                className="save-btn ml-1"
                disabled={!remark}
                onClick={() => finaliseWithoutData()}>
                FINALISE
              </Button>
            </div>
          </>
        </Modal>
      </Spin>
    </Modal>
  );
}
