/* eslint-disable indent */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { Select, Button, Input, Card, message, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import "./style.scss";
import Heading from "../../../components/Heading";
import urls from "../../../api/urls";
import NormalAPI from "../../../api";
import { capitalize } from "../../../utils";
import axios from "axios";
import { APIs } from "../../../utils/worker";
import API from "../../../api";

const { Option } = Select;

function HubCpLinking() {
    const { GET_HUB_LISTS,HUB_TO_CP_MASTER_FOR_BAGGING} = urls;
    let getRols = JSON.parse(localStorage.getItem("userinfo"));
    const role = getRols?.roles[0]?.replace(/.*_/, "");

    const [hubListDropdown, setHubListDropdown] = useState([]);
    const [selectedHub, setSelectedHub] = useState([]);
    const [ownCpList, setOwnCpList] = useState([]);
    const [otherCpList, setOtherCpList] = useState([]);
    const [allCPList, setAllCPList] = useState([]);
    const [cpListData, setCpListData] = useState([]);
    const [HubName, setHubName] = useState("");
    useEffect(() => {
        getHubLists();
    }, []);
    useEffect(() => {
        const userName = localStorage.getItem("userId");
        if (userName) {
            let name = JSON.parse(userName);
            setHubName(name?.premises?.premiseName);
            if (role == "HUBADMIN") {
                getAssignedCP(name?.userInfo?.premiseId);
                setSelectedHub(name?.userInfo?.premiseId);
                getCpListStateWise(name?.premises?.state);
            }else{
                getCpList();
            }
        }
    }, []);
   
    const selectHubHandle = (data, key) => {
        setSelectedHub(key?.key);
        getAssignedCP(key?.key);
    };
    const handleCPSelection = (selectedValues) => {
        const selectedCPs = allCPList?.filter((cp) => selectedValues?.includes(cp.premiseId));
        setCpListData(selectedCPs);
    };
    const clearSelection = () => {
        setCpListData([]);
    };
    const confirmDelete = (cpId) => {
        Modal.confirm({
            title: "Are you sure?",
            content: "Do you really want to remove this CP?",
            okText: "Yes, Delete",
            cancelText: "Cancel",
            onOk: () => deleteCP(cpId),
        });
    };
    const getHubLists = async () => {
        let response = await NormalAPI.Api(GET_HUB_LISTS).getWithHeader();
        if (response?.status) {
            let temp = response?.response?.data?.map((val) => ({
                label: capitalize(val?.premiseName),
                value: val?.premiseId,
            }));
            setHubListDropdown(temp);
        } else {
            setHubListDropdown([]);
        }
    };
    const getCpList = async () => {
        try {
            let res = await axios.get(`${APIs.baseURL}/premise-service/v1/premise/CP`);
            if (res?.status === 200) {
                let temp = res.data?.data.map((item) => ({
                    premiseName: capitalize(item?.premiseName),
                    premiseId: item?.premiseId,
                }));
                setAllCPList(temp);
            }
        } catch (err) {
            message.error(err?.response?.data?.message || "Something went wrong");
        }
    };
    const assingCPToHub = async () => {
        if (selectedHub.length === 0) {
            message.error("Please select hub");
            return;
        }
        if (cpListData.length === 0) {
            message.error("Please select CP");
            return;
        }
        let data = {
            premiseId: +selectedHub,
            cpIds: cpListData.map((cp) => cp.premiseId),
        };
        try {
            let res = await API.Api(HUB_TO_CP_MASTER_FOR_BAGGING).postWithHeader(data);
            console.log("res", res);
            if (res?.response?.status === 200) {
                setCpListData([]);
                getAssignedCP(+selectedHub);
                message.success(res?.response?.data?.message || "CP assigned successfully");
            }
        } catch (err) {
            message.error(err?.response?.data?.message || "Something went wrong");
        }
    };
    const getCpListStateWise = async (stateName) => {
        try {
            let res = await API.Api(HUB_TO_CP_MASTER_FOR_BAGGING+"/state/"+stateName).getWithHeader();
            console.log("res", res);
            if (res?.status === true) {
                let temp = res?.response?.data?.map((item) => ({
                    premiseName: capitalize(item?.cpName),
                    premiseId: item?.cpId,
                }));
                console.log("temp", temp);
                
                setAllCPList(temp);
            }
        } catch (err) {
            message.error(err?.response?.data?.message || "Something went wrong");
        }
    };
    const getAssignedCP = async (hubId) => {
        try {
            let res = await API.Api(`${HUB_TO_CP_MASTER_FOR_BAGGING}/${hubId}`).getWithHeader();
            if (res?.response?.status === 200) {
                let tempOwnCp = res?.response?.data?.filter((item) => item?.ownCp);
                let tempOtherCp = res?.response?.data?.filter((item) => !item?.ownCp);
                setOwnCpList(tempOwnCp);
                setOtherCpList(tempOtherCp);
            }
        } catch (err) {
            message.error(err?.response?.data?.message || "Something went wrong");
        }
    };

    const deleteCP = async (cpId) => {
        let data = {
            premiseId: +selectedHub,
            cpIds: [cpId],
        };
        try {
            let res = await API.Api(HUB_TO_CP_MASTER_FOR_BAGGING).deleteIdHeader(data);
            if (res?.response?.status === 200) {
                message.success(res?.response?.data?.message);
                getAssignedCP(+selectedHub);
            }
        } catch (err) {
            message.error(err?.response?.data?.message);
        }
    };

    return (
        <Card className="hub-cp-container">
            <Heading title="Out Scan CP Master" />
            <div className="hub-cp-layout">
                {/* Left Section - Hub Name & CPs */}
                <div className="hub-section">
                    <div className="hub-name">
                        <strong>Hub Name:</strong>
                        <Select
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option?.children?.toLowerCase()?.includes(input?.toLowerCase())
                            }
                            placeholder="Please Select Hub"
                            value={HubName && hubListDropdown.find(h => h?.label?.toLowerCase() === HubName?.toLowerCase()) ? HubName : undefined}
                            onSelect={(data, key) => selectHubHandle(data, key)}
                            disabled={role === "HUBADMIN"}
                        >
                            {hubListDropdown?.map((hubs) => (
                                <Option key={hubs?.value} value={hubs?.value}>
                                    {hubs?.label}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    {/* Own CP Card */}
                    <Card title="Own CP" extra={<Input placeholder="Search Cp" hidden/>} className="cp-card">
                        <p className="comma-separated-list">
                            {ownCpList.length > 0 ? (
                                ownCpList.map((cp, index) => (
                                    <span key={index} className="cp-item">
                                        <span className="cp-name">{cp?.cpName}</span>
                                        {role !== "HUBADMIN" && (
                                            <DeleteOutlined
                                                className="delete-icon"
                                                onClick={() => confirmDelete(cp?.cpId)}
                                            />
                                        )}
                                    </span>
                                ))
                            ) : (
                                "No CPs available"
                            )}
                        </p>
                    </Card>

                    {/* Other CP Card */}
                    <Card title="Other CP" extra={<Input placeholder="Search Cp" hidden/>} className="cp-card">
                        <p className="comma-separated-list">
                            {otherCpList.length > 0 ? (
                                otherCpList.map((cp, index) => (
                                    <span key={index} className="cp-item">
                                        {cp.cpName} <DeleteOutlined className="delete-icon" onClick={() => confirmDelete(cp.cpId)} />
                                    </span>
                                ))
                            ) : (
                                "No CPs available"
                            )}
                        </p>
                    </Card>
                </div>

                {/* Right Section - Channel Partner */}
                <div className="channel-partner-section">
                    <div className="channel-partner">
                        <strong>Channel Partner:</strong>
                        <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option?.children?.toLowerCase()?.includes(input?.toLowerCase())
                            }
                            placeholder="Please Select CP"
                            value={cpListData.map((cp) => cp?.premiseId)}
                            onChange={handleCPSelection}
                        >
                            {allCPList?.map((cps) => (
                                <Option key={cps.premiseId} value={cps?.premiseId}>
                                    {cps.premiseName}
                                </Option>
                            ))}
                        </Select>
                        <div className="button-group">
                            <Button onClick={() => clearSelection()}>Clear</Button>
                            <Button type="primary"  onClick={() => assingCPToHub()}  disabled={!cpListData || cpListData.length === 0}>Save</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default HubCpLinking;
