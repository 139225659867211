/* eslint-disable indent */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Table, Switch, Tag, message } from "antd";
import axios from "axios";
import "./style.scss"; // Import CSS file
import Heading from "../../../components/Heading";
import { getUserAndPremiseId } from "../../../utils";
import envUrls from "../../../config/env-urls";

const REACT_APP_ENV = process.env.REACT_APP_ENV || "dev";
const API_URL = envUrls[REACT_APP_ENV].API.DrsMasterURL; // Replace with your API URL
const HEADERS = {
    "Content-Type": "application/json",
};

const DrsMaster = () => {
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(100);
    const [listCount, setListCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const getUserInfo = getUserAndPremiseId();
    const premiseId = getUserInfo?.premiseId;
    // Fetch data from API
    const fetchData = async () => {
        if (API_URL !== "") {
            try {
                const response = await axios.get(API_URL + "cp/hublist/" + premiseId, { headers: HEADERS, credentials: true });
                if (response.data && Array.isArray(response.data)) {
                    setData(response.data);
                    setListCount(response.data.length);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Handle toggle for switches
    const handleToggle = async (cpid, field) => {
        try {
            const response = await axios.post(
                API_URL+"drs/toggle",
                { spId: cpid.toString(), field: field },
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            );
    
            if (response.status === 201) {
                message.success(`${field} updated successfully!`);
                setData((prevData) =>
                    prevData.map((item) =>
                        item.cpid === cpid ? { ...item, [field]: item[field] === 1 ? 0 : 1 } : item
                    )
                );
                fetchData(); // Refresh data after toggle
            } else {
                message.error("Failed to update field. Please try again.");
            }
        } catch (error) {
            console.error("API Error:", error);
            message.error("Error updating field. Check console for details.");
        }
    };

    const columns = [
        { title: "CP ID", dataIndex: "cpid", key: "cpid", align: "center" },
        { title: "CP Name", dataIndex: "cpname", key: "cpname", align: "left" },
        { title: "City", dataIndex: "city", key: "city", align: "left" },
        {
            title: "DrsOtpVerify",
            dataIndex: "DrsOtpVerify",
            key: "DrsOtpVerify",
            align: "center",
            render: (value, record) => (
                <Switch
                    checked={value === 1}
                    onChange={() => handleToggle(record.cpid, "DrsOtpVerify")}
                    className={`custom-switch ${value === 1 ? "switch-active" : "switch-inactive"}`}
                />
            ),
        },
        {
            title: "DrsLockODA",
            dataIndex: "DrsLockODA",
            key: "DrsLockODA",
            align: "center",
            render: (value, record) => (
                <Switch
                    checked={value === 1}
                    onChange={() => handleToggle(record.cpid, "DrsLockODA")}
                    className={`custom-switch ${value === 1 ? "switch-active" : "switch-inactive"}`}
                />
            ),
        },
        {
            title: "Status",
            dataIndex: "IsActive",
            key: "IsActive",
            align: "center",
            render: (value) => (
                <Tag color={value === 1 ? "green" : "red"}>
                    {value === 1 ? "Active" : "Inactive"}
                </Tag>
            ),
        },
    ];

    const itemRender = (_, type, originalElement) => {
        if (type === "prev") return <a>Previous</a>;
        if (type === "next") return <a>Next</a>;
        return <div>{originalElement}</div>;
    };

    const onPageChange = (current, pageSize) => {
        setCurrentPage(current);
        setPageSize(pageSize);
    };

    return (
        <div className="p2 consolidated-tab-cntr">
            <Heading title="DRS Config" />
            <div className="hub-table-container">
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey="cpid"
                    bordered
                    className="table-head-sticky"
                    pagination={{
                        pageSizeOptions: ["50", "100", "500", "1000"],
                        pageSize: pageSize,
                        total: listCount,
                        current: currentPage,
                        itemRender: itemRender,
                        onChange: onPageChange,
                    }}
                />
            </div>
        </div>
    );
};

export default DrsMaster;
