import axios from "axios";
import { getUserAndPremiseId } from "../utils";
import { getPremiseId } from "../utils/worker";
import { v4 as uuidv4 } from "uuid";
let userData = JSON.parse(localStorage.getItem("userinfo"));
// const {accessToken} = userData;
const getUserInfo = getUserAndPremiseId();
const userId = getUserInfo?.userId;

const premiseId = getPremiseId();

const API = {
  Api: (url) => {
    return {
      NodeGetWithPremise: (params) =>
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "X-Request-ID": uuidv4(),
            },
            params: {
              ...params,
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      NodeGetWithCustomHeader: (customHeader) =>
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "X-Request-ID": uuidv4(),
              ...customHeader,
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),

      NodePutPremiseIdHeader: (data) =>
        axios
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),

      NodePostPremiseIdHeader: (data) =>
        axios
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      getWithoutBearer: () =>
        axios
          .get(url, {
            headers: {
              Authorization: userData?.accessToken,
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      get: () =>
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      getWithHeader: () =>
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              token: userData?.accessToken,
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      getWithPremiseHeader: () =>
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              token: userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              type: 2,
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      getWithUserNPremiseHeader: () =>
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "USER-ID": userId,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      getWithPremiseIdHeader: () =>
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      getWithAllHeaders: () =>
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "USER-ID": userId,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              token: userData?.accessToken,
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      getCustomHeader: (headers) =>
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "X-Request-ID": uuidv4(),
              ...headers,
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),

      getwithParams: (params) =>
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "USER-ID": userId,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "X-Request-ID": uuidv4(),
            },
            params: {
              ...params,
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      post: (data) =>
        axios
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      postWithHeader: (data) =>
        axios
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              token: userData?.accessToken,
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      postCustomHeader: (data, headers) =>
        axios
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "X-Request-ID": uuidv4(),
              ...headers,
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      postWithPrmiseHeader: (data) =>
        axios
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),

      postIdHeader: (data) =>
        axios
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "USER-ID": userId,
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      put: (data) =>
        axios
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      putCustomHeader: (data, headers) =>
        axios
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "X-Request-ID": uuidv4(),
              ...headers,
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      putCustomIdHeader: (data, customHeader) =>
        axios
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "USER-ID": userId,
              "X-Request-ID": uuidv4(),
              ...customHeader,
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      putWithHeader: (data) =>
        axios
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              token: userData?.accessToken,
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      putIdHeader: (data) =>
        axios
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "USER-ID": userId,
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      putPremiseIdHeader: (data) =>
        axios
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "X-Request-ID": uuidv4(),
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      patchCustomHeader: (data, headers) =>
        axios
          .patch(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "X-Request-ID": uuidv4(),
              ...headers,
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      patchIdHeader: (data) =>
        axios
          .patch(url, data, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "X-Request-ID": uuidv4(),
              "USER-ID": userId,
            },
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
      deleteIdHeader: (data) =>
        axios
          .delete(url, {
            headers: {
              Authorization: "Bearer " + userData?.accessToken,
              "SMCS-PREMISE-ID": premiseId.split("Bearer")[1],
              "X-Request-ID": uuidv4(),
              "USER-ID": userId,
            },
            data,
          })
          .then((response) => {
            return { response: response, status: true };
          })
          .catch((error) => {
            return { response: error.response, status: false };
          }),
    };
  },
  ApiWithAuth: (url, token) => {
    return {
      get: () =>
        axios
          .get(url, {
            headers: {
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest",
              "X-Request-ID": uuidv4(),
              Authorization: token,
            },
          })
          .then((response) => {
            return response?.data;
          })
          .catch((error) => {
            return error.response;
          }),
      post: (data) =>
        axios
          .post(url, data, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Requested-With": "XMLHttpRequest",
              "X-Request-ID": uuidv4(),
              Authorization: token,
            },
          })
          .then((response) => {
            return response?.data;
          })
          .catch((error) => {
            return error.response;
          }),
    };
  },
};
export default API;
