import React, { useState, useRef, useEffect } from "react";
import { Drawer, Button, message, Row, Spin } from "antd";
import { getUserAndPremiseId, overflowFunc } from "../../../utils";
import axios from "axios";

import { APIs, getToken } from "../../../utils/worker";

import download_pin_icon from "../../../assests/images/SVG/upload-icon.svg";
import cross_button from "../../../assests/images/CrossButton.svg";
import SamplePacketingGuide from "../../../assests/documents/SamplePacketingGuide.xlsx";
import SamplePincodeMaster from "../../../assests/documents/SamplePincodeMaster.csv";
import SampleRouteSurface from "../../../assests/documents/OTC_SURFACE.xlsx";
import SampleRouteAir from "../../../assests/documents/OTC_AIR.xlsx";

const getUserInfo = getUserAndPremiseId();
const userId = getUserInfo?.userId;
const token = getToken();

const UploadDrawer = ({
  type,
  open,
  url,
  title,
  docType,
  label,
  onClose = () => {},
  handleCallBack = () => {},
}) => {
  const imgLogoRef = useRef(null);
  const [acceptedFile, setacceptedFile] = useState("xlsx");
  const [fileName, setFileName] = useState();
  const [fileInfo, setfileInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (docType === ".csv") {
      setacceptedFile(docType);
    }
  }, []);

  const logoUpload = () => {
    imgLogoRef.current.click();
  };

  const onChangeLogo = (e) => {
    try {
      if (e?.target || e?.dataTransfer) {
        e.preventDefault();
        let files;
        if (e?.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        let x = files[0].name.split(".");
        let y = x[x.length - 1];

        if (files[0].size > 5000000) {
          message.warning("Maximum upload size is 5 MB.", [3]);
        } else if (acceptedFile.includes(y)) {
          const reader = new FileReader();
          reader.onload = () => {};
          setFileName(e.target.files[0].name);
          setfileInfo(e.target.files[0]);
          // message.success("Added Successfully");
          reader.readAsDataURL(files[0]);
        } else {
          if (label?.length) {
            return message.warning("Only csv files are allowed.", [3]);
          } else {
            return message.warning("Only xlsx files are allowed.", [3]);
          }
        }
      }
    } catch (error) {
      //need for error handling
      console.log(error);
    }
  };

  function removeFile() {
    document.getElementById("uploadFile").value = "";
    // message.success("File removed successfully");
    setFileName("");
    setfileInfo([]);
  }

  const UploadFile = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("file", fileInfo);

    let webToken = token.slice(6);
    const headers = {
      "USER-ID": userId,
      Authorization: token,
      token: webToken,
    };
    axios
      .post(`${APIs.baseURL}${url}`, formData, { headers })
      .then((response) => {
        message.success(response?.data?.message || "Route uploaded successfully");
        if(response?.data?.failedUpload?.length>0){
          message.error(response?.data?.failedUpload?.join("\n"));
        }
        setLoading(false);

        handleCallBack();
        removeFile();
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (open) {
      removeFile();
    }
  }, [open]);
  const defaultLabel = " only xlsx formats are supported";

  return (
    <Drawer
      title={title}
      placement="right"
      closable={true}
      onClose={onClose}
      open={open}
      closeIcon={<img src={cross_button} width={20} height={20} />}
      width="560"
      maskClosable={false}
      className="upload-pincode-drawer"
    >
      <Spin spinning={loading}>
        <div className="pincode-drawer-container"></div>
        <div className="content-box">
          <img src={download_pin_icon} width={35} height={35} />
          <p className="f-21 fw-600 mt-1">Choose the file to be uploaded</p>
          <p className="label">
            {label?.length ? [label] : defaultLabel}
            <br />
            Maximum upload file size is 5 MB
          </p>
          <div>
            <Button type="primary" onClick={logoUpload} className="upload-btn">
              <span className="capitalize">Upload File</span>
            </Button>
            <input
              type="file"
              onChange={onChangeLogo}
              hidden
              ref={imgLogoRef}
              accept={acceptedFile}
              id={"uploadFile"}
            />

            <Row align="baseline" justify="center" className="file-name">
              {overflowFunc(fileName, 100)}
              {fileName?.length ? (
                <Button className="remove-btn" onClick={removeFile}>
                  X
                </Button>
              ) : null}
            </Row>
            {/* <Link to="/" target="_blank" download>
                <span className="sample-link fw-500">
                  Download sample template for Import
                </span>
              </Link> */}
            {type === "Packeting Guide" ? (
              <a
                href={SamplePacketingGuide}
                download="SamplePacketingGuide.xlsx"
              >
                {" "}
                Download sample template for Import{" "}
              </a>
            ) : type === "Pincode Master" ? (
              <a href={SamplePincodeMaster} download="SamplePincodeMaster.csv">
                {" "}
                Download sample template for Import{" "}
              </a>
            ) : type === "Route Surface" ? (
              <a href={SampleRouteSurface} download="SampleRouteSurface.xlsx">
                {" "}
                Download sample template for Import{" "}
              </a>
            ) : type === "Route Air" ? (
              <a href={SampleRouteAir} download="SampleRouteAir.xlsx">
                {" "}
                Download sample template for Import{" "}
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="center mt-2">
          <Button className="cancel-btn" onClick={onClose}>
            {"CANCEL"}
          </Button>
          <Button
            className={
              fileInfo.length === 0 ? "ml-1 btn-style" : "ml-1  save-btn"
            }
            type="primary"
            onClick={UploadFile}
            disabled={fileInfo.length === 0 ? true : false}
          >
            {"CONFIRM"}
          </Button>
        </div>
      </Spin>
    </Drawer>
  );
};
export default UploadDrawer;
