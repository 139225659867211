import React from "react";
import { Tabs, Empty } from "antd";
import AllManifestList from "./allManifestList";
import CpWiseManifest from "./cpWiseManifestList";
import Heading from "../../../components/Heading";
import sorting from "../../../assests/images/SVG/sort-white.svg";
import { useSearchParams } from "react-router-dom";

const items = [
  {
    key: "1",
    label: "All Manifests",
    children: <AllManifestList />,
  },
  {
    key: "2",
    label: "CP Wise Manifest",
    children: <CpWiseManifest />,
  },
  {
    key: "3",
    label: "3PL Wise Manifest",
    children: <Empty />,
  },
  {
    key: "4",
    label: "Hub Delivery",
    children: <Empty />,
  },
];
const index = () => {
  const [searchParams] = useSearchParams();
  const tabFromUrl = searchParams.get("tab") || "1"; 
  return (
    <div className="p-1 master-main-container">
      <Heading title={"Manifest List"} imgSrc={sorting} />
      <Tabs defaultActiveKey={tabFromUrl} items={items} />
    </div>
  );
};
export default index;
