/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/display-name */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { capitalise } from "../utils";
// import logo from "../assests/images/shree-maruti-logo.svg";
// import HamIcon from "../assests/images/maruti/hamburgerIcon.svg";
// import AppLogo from "../assests/shree-maruti-logo.png";

// Define styles

const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontSize: 10,
    fontFamily: "Helvetica",
  },
  logo: {
    width: 100,
    height: 20,
    paddingBottom: 2,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    justifyContent: "center",
    // marginBottom: 20,
  },
  section: {
    marginBottom: 10,
  },
  boldText: {
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCellHeader: {
    backgroundColor: "#f0f0f0",
    padding: 5,
    fontWeight: "bold",
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  tableCell: {
    padding: 5,
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  noBorderRight: {
    borderRightWidth: 0,
  },

  border: {
    border: "1px solid black",
    minHeight: "93vh",
  },
  addressDetails: {
    width: "35%",
    fontSize: 7,
    paddingLeft: 2,
    paddingTop: 2,
  },
  bagDetails: { flexDirection: "row", columnGap: 20, padding: 2 },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    marginVertical: 8,
  },

  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },
  headerr: {
    backgroundColor: "#f0f0f0",
  },
  headerText: {
    fontWeight: "bold",
  },
  cell: {
    flex: 1,
    padding: 8,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "#ddd",
  },

  awbNumber: {
    flex: 2, // Larger column
    fontSize: 7,
  },
  sourceCp: {
    flex: 2, // Larger column
    fontSize: 7,
  },
  destinationCp: {
    flex: 2,
    fontSize: 7,
  },
  senderName: {
    flex: 2,
    fontSize: 7,
  },
  receiverName: {
    flex: 2,
    fontSize: 7,
  },
  weight: {
    flex: 0.8,
    fontSize: 7,
  },
  remarks: {
    flex: 1,
    fontSize: 7,
  },
  value: {
    flex: 0.8,
    fontSize: 7,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const getFormattedDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0"); // Add leading zero
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = today.getFullYear();

  return `${day}/${month}/${year}`;
};

// Dynamic Data PDF Component
const MyDocument = ({ masterData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Header */}
      <View style={styles.header} fixed>
        <Image
          style={styles.logo}
          src={require("../assests/shree-maruti-logo.png")}
        />
      </View>
      {masterData?.map((data, index) => (
        <View key={index} {...(index !== 0 ? { break: true } : {})}>
          <View style={styles.border}>
            <View style={{ flexDirection: "row",justifyContent: "space-between",padding: 3, }}>
              {data?.travelBy.toLowerCase() === "surface" ? (
                <View style={styles.section}>
                  <Text style={styles.boldText}>
                    Vehicle LCR: {data?.vehicleLcrId}
                  </Text>
                  <Text>Driver Name: {data?.driverName}</Text>
                  <Text>Vehicle No: {data?.vehicleNo}</Text>
                  {data?.type === "Rail" && (
                    <Text>Mode & RR No: {`${data?.type} & ${data?.trainNo}`}</Text>
                  )}
                </View>
              ) : (
                <View>
                  <Text style={styles.boldText}>
                    Airway bill No: {data?.airwayBillNo}
                  </Text>
                  <Text>Flight No: {data?.flightNo}</Text>
                  <Text>Flight Name: {data?.flightName}</Text>
                </View>
              )}
              <View style={styles.section}>
                <Text style={styles.boldText}>Source: {data?.sourceHub}</Text>
                <Text>Trip ID: {data?.tripId}</Text>
                <Text style={styles.addressDetails}>{data.addressDetails}</Text>
              </View>
              <View> 
                <Text>Destination: {data.destinationHub.toUpperCase()}</Text>
                <Text>Weight: {data.lcrDetails.lcrWeight}</Text>
                <Text>Date:{getFormattedDate()}</Text>
              </View>
            </View>
            <View>
              <Text style={{ paddingLeft: 4 }}>
                LCR NO. - {data?.lcrDetails?.lcrNum} (
                {data?.lcrDetails?.lcrBagContent?.length} Bag(s),
                {data?.lcrDetails?.individualShipmentsCount} Indv. Shipment(s))
              </Text>
              <View style={styles.divider} />
            </View>
            {data?.lcrDetails?.lcrBagContent?.map((item, i) => (
              <>
                <View key={i} {...(i !== 0 ? { break: true } : {})}>
                <View style={styles.bagDetails}>
                  <Text> To:{item.bagDestination} </Text>
                </View>
                 <View style={styles.divider} />
                {item.bags?.map((item, i) => (
                  <View key={i}>
                    {/* <View style={styles.divider} /> */}
                    <View style={{ flexDirection: "row", justifyContent: "space-between",}}>
                      <View style={styles.section}>
                          <Text> BAG NO.-  {item.bagNum} ({item.totalShipments} Shipment(s))</Text>
                      </View>
                      <View style={styles.section}>
                        <Text> Weight: {item.bagWeight} </Text>
                        <Text> {capitalise(item.travelBy)}</Text>
                      </View>
                      <View></View>
                    </View>
                    <View style={[styles.row, styles.headerr]}>
                      <Text style={[styles.cell, styles.awbNumber]}>
                        DOCUMENT NO
                      </Text>
                      <Text style={[styles.cell, styles.sourceCp]}>FROM</Text>
                      <Text style={[styles.cell, styles.destinationCp]}>
                        TO
                      </Text>
                      <Text style={[styles.cell, styles.senderName]}>
                        SENDER
                      </Text>
                      <Text style={[styles.cell, styles.receiverName]}>
                        RECEIVER
                      </Text>
                      <Text style={[styles.cell, styles.weight]}>WEIGHT</Text>
                      <Text style={[styles.cell, styles.remarks]}>REMARKS</Text>
                      <Text style={[styles.cell, styles.value]}>VALUE</Text>
                    </View>

                    {item.bagContent?.map((item, index) => (
                      <View key={index} style={styles.row}>
                        <Text style={[styles.cell, styles.awbNumber]}>
                          {item.awbNumber}
                        </Text>
                        <Text style={[styles.cell, styles.sourceCp]}>
                          {item.sourceCp}
                        </Text>
                        <Text style={[styles.cell, styles.destinationCp]}>
                          {item.destinationCp}
                        </Text>
                        <Text style={[styles.cell, styles.senderName]}>
                          {item.senderName}
                        </Text>
                        <Text style={[styles.cell, styles.receiverName]}>
                          {item.receiverName}
                        </Text>
                        <Text style={[styles.cell, styles.weight]}>
                          {item.weight}
                        </Text>
                        <Text style={[styles.cell, styles.remarks]}>
                          {(item?.description==null?"":item?.description) + (item?.content == null ? "" : item?.content)}
                        </Text>
                        <Text style={[styles.cell, styles.value]}>
                          {item.value}
                        </Text>
                      </View>
                    ))}

                    <View style={styles.divider} />
                  </View>
                ))}
                </View>
              </>
            ))}
            {data?.lcrDetails?.individualShipmentsCount != 0 && (
              <>
                <Text style={{ paddingLeft: 3 }}>
                  Total Individual Shipments :
                  {data?.lcrDetails?.individualShipmentsCount}
                </Text>

                <View style={[styles.row, styles.headerr]}>
                  <Text style={[styles.cell, styles.awbNumber]}>
                    DOCUMENT NO
                  </Text>
                  <Text style={[styles.cell, styles.sourceCp]}>FROM</Text>
                  <Text style={[styles.cell, styles.destinationCp]}>TO</Text>
                  <Text style={[styles.cell, styles.senderName]}>SENDER</Text>
                  <Text style={[styles.cell, styles.receiverName]}>
                    RECEIVER
                  </Text>
                  <Text style={[styles.cell, styles.weight]}>WEIGHT</Text>
                  <Text style={[styles.cell, styles.remarks]}>REMARKS</Text>
                  <Text style={[styles.cell, styles.value]}>VALUE</Text>
                </View>
                {data.lcrDetails.individualShipments?.map((item, index) => (
                  <View key={index} style={styles.row}>
                    <Text style={[styles.cell, styles.awbNumber]}>
                      {item.awbNumber}
                    </Text>
                    <Text style={[styles.cell, styles.sourceCp]}>
                      {item.sourceCp}
                    </Text>
                    <Text style={[styles.cell, styles.destinationCp]}>
                      {item.destinationCp}
                    </Text>
                    <Text style={[styles.cell, styles.senderName]}>
                      {item.senderName}
                    </Text>
                    <Text style={[styles.cell, styles.receiverName]}>
                      {item.receiverName}
                    </Text>
                    <Text style={[styles.cell, styles.weight]}>
                      {item.weight}
                    </Text>
                    <Text style={[styles.cell, styles.remarks]}>
                      {(item?.description==null?"":item?.description) + (item?.content == null ? "" : item?.content)}
                    </Text>
                    <Text style={[styles.cell, styles.value]}>
                      {item.value}
                    </Text>
                  </View>
                ))}
              </>
            )}
            {/* <View style={styles.divider} /> */}
          </View>
        </View>
      ))}
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default MyDocument;
