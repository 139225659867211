import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import Heading from "../../../components/Heading";
import RouteTable from "./RouteTable";
import "./index.scss";
import "../index.scss";
import constants from "../../../constants/constants";
const { ROLES } = constants;
const { HO_ADMIN } = ROLES;

const RouteMaster = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [roles, setRoles] = useState();

  useEffect(() => {
    setRoles(JSON.parse(localStorage.getItem("userinfo")));
  }, []);

  const changeTab = (key) => {
    setActiveKey(key);
  };

  return (
    <div className="p-2">
      <Heading
        title={
          roles && roles?.roles?.includes(HO_ADMIN)
            ? "Vehicle Route Master"
            : "Vehicle Route Master"
        }
      />
      <div className="rate-card-tab-container">
        <Tabs
          activeKey={activeKey}
          onChange={changeTab}
          items={[
            {
              label: "Surface",
              key: "1",
              children: <RouteTable primaryMode="SURFACE" />,
            },
            {
              label: "Air",
              key: "2",
              children: <RouteTable primaryMode="AIR" />,
            },
          ]}
        />
      </div>
    </div>
  );
};
export default RouteMaster;
