/* eslint-disable indent */
import React from "react";
import { Button, Input, Select, message, Popconfirm } from "antd";
import ScannedShipment from "../../../components/ScannedShipment";
import {
  capitaliseWithHyphen,
  // checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../utils";
import { QuestionCircleOutlined } from "@ant-design/icons";
import search_icon from "../../../assests/images/search-icon.svg";

export function headSection(
  currentScanNum,
  setcurrentScanNum,
  setSelectSection,
  selectSection,
  baggingData,
  searchBag,
  setShowCreateBagModal,
  //getBagBarCodeNum,
  totaldata,
  scanningData,
  scanBarCode
) {
  let onlyCreatedBags = baggingData;

  // ?.filter((e) =>
  //   e?.bagList?.some((item) => item?.status?.toUpperCase() === "CREATED")
  // );
  return (
    <div className="space-between p-1">
      <Input
        placeholder="Enter AWB number to scan"
        className="w-20"
        onKeyPress={(event) => {
          if (
            // checkNumbervalue(event) ||
            checkSpace(event) ||
            checkSpecialCharacter(event)
          ) {
            event.preventDefault();
          }
        }}
        onChange={(e) => setcurrentScanNum(e.target.value)}
        onPressEnter={async (e) => {
          // eslint-disable-next-line no-constant-condition
          if (e.key == "Enter") {
            if (selectSection !== null) {
              let barcodePresent = baggingData.some((e) =>
                e.bagList.some((item) =>
                  item.bagContents.some(
                    (contentData) => contentData.barcodeNo == currentScanNum
                  )
                )
              );
              if (barcodePresent) {
                message.error("Shipment already present in bag.");
              } else {
                setTimeout(() => scanBarCode(false), 100);
              }
            } else {
              message.error("Please select section for scanning");
            }
          }
        }}
      />
      <div className="align-center">
        <ScannedShipment
          scannedData={scanningData ? scanningData : 0}
          totalData={totaldata + scanningData}
        />
        <Select
          className="select-without-form"
          showSearch
          placeholder="Select section"
          onChange={(e) => setSelectSection(e)}
          options={onlyCreatedBags?.map((item, i) => {
            return {
              label: capitaliseWithHyphen(
                `${item?.destination}-${item?.product}-${item?.contentType}-${item?.mode}`
              ),
              value: i,
            };
          })}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          value={selectSection}
        />
        <Input
          placeholder={"Search..."}
          bordered
          onChange={searchBag}
          className="bag-search-input"
          suffix={<img src={search_icon} />}
        />
        {baggingData?.some((item) =>
          item?.bagList?.some(
            (bag) => bag?.status === "CREATED" && bag?.bagContents?.length > 0
          )
        ) ? (
          <Popconfirm
            title={
              <>
                <span className="fw-500">
                  Finalize the open bags to avoid data loss.
                </span>
                <br />
                <span className="fw-500">Do you want to proceed?</span>
              </>
            }
            okText="Yes"
            cancelText="No"
            onConfirm={() => setShowCreateBagModal(true)}
            //onCancel={()=)}
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
          >
            <Button className="save-btn ml-1">+ INITIATE BAG</Button>
          </Popconfirm>
        ) : (
          <Button
            className="save-btn ml-1"
            onClick={() => {
              setShowCreateBagModal(true);
            }}
          >
            + INITIATE BAG
          </Button>
        )}
      </div>
    </div>
  );
}
