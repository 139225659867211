import { Button, Card, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import Heading from "../../../components/Heading";
import "./style.scss";
import NormalAPI from "../../../api";
import urls from "../../../api/urls";
import { capitalize } from "../../../utils";
import { getPacketingGuideFromCplist } from "../../../actionsMethods/masterMethods";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import API from "../../../api";
import axios from "axios";
import { APIs } from "../../../utils/worker";

function HubCpLinking() {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { GET_HUB_LISTS, PATCH_CP_ALLOCATION } = urls;

  const getFromCpListResponse = useSelector(
    (state) => state?.masterReducer?.getPacketingGuideFromCplistReducer,
    shallowEqual
  );
  const { result: allCps } = getFromCpListResponse;
  let getRols = JSON.parse(localStorage.getItem("userinfo"));
  const role = getRols?.roles[0]?.replace(/.*_/, "");

  const [hubListDropdown, setHubListDropdown] = useState([]);
  const [selectedHubs, setSelectedHubs] = useState("");
  const [cpListData, setCpListData] = useState([]);
  const [allCPList, setAllCPList] = useState([]);
  const [HubName, setHubName] = useState("");

  useEffect(() => {
    getHubLists();
    getCpList();
  }, []);

  useEffect(() => {
    const userName = localStorage.getItem("userId");
    if (userName) {
      let name=JSON.parse(userName);
      setHubName(name?.premises?.premiseName);
    }
  }, []);

  useEffect(() => {
    if (allCps?.length) {
      setCpListData(allCps);
    }
  }, [allCps]);

  useEffect(() => {
    if (HubName && hubListDropdown.length) {
      const matchedHub = hubListDropdown.find(
        (hub) => hub?.label?.toLowerCase() === HubName?.toLowerCase()
      );
      if (matchedHub) {
        selectHubHandle(matchedHub?.value, { key: matchedHub?.value });
      }
    }
  }, [HubName, hubListDropdown]); 

  const getHubLists = async () => {
    let response = await NormalAPI.Api(GET_HUB_LISTS).getWithHeader();
    if (response?.status) {
      let temp = response?.response?.data?.map((val) => ({
        label: capitalize(val?.premiseName),
        value: val?.premiseId,
      }));
      setHubListDropdown(temp);
    } else {
      setHubListDropdown([]);
    }
  };

  const getCpList = async () => {
    try {
      let res = await axios.get(`${APIs.baseURL}/premise-service/v1/premise/CP`);
      if (res?.status === 200) {
        let temp = res.data?.data.map((item) => ({
          premiseName: capitalize(item?.premiseName),
          premiseId: item?.premiseId,
        }));
        setAllCPList(temp);
      }
    } catch (err) {
      message.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  const selectHubHandle = (data, key) => {
    dispatch(getPacketingGuideFromCplist(key?.key));
    setSelectedHubs(key?.key);
  };

  const handleCPSelection = (selectedValues) => {
    const selectedCPs = allCPList?.filter((cp) => selectedValues?.includes(cp.premiseId));
    setCpListData(selectedCPs);
  };

  const showDeleteConfirm = (id) => {
    setCpListData((prev) => prev?.filter((item) => item?.premiseId !== id));
  };

  const saveCp = async () => {
    let cpPayload = {
      cpId: cpListData?.map((item) => item?.premiseId),
    };

    let res = await API.Api(`${PATCH_CP_ALLOCATION}${selectedHubs}`).patchCustomHeader(cpPayload);

    if (res?.status) {
      message.success(res?.response?.data[0]?.message);
    }else{
      message.error(res?.response?.data[0]?.message);
    }
  };

  return (
    <Card className="hub-pincode-container">
      <Heading title="Hub to CP Linking" />
      <div className="hub-pincode-wrapper">
        <Card title="Select Hub" className="hub-pincode-card">
          <div className="pincode-input-container">
            <Select
              style={{ width: "90%" }}
              showSearch
              filterOption={(input, option) =>
                option?.children?.toLowerCase()?.includes(input?.toLowerCase())
              }
              placeholder="Select Hub Name"
              value={HubName && hubListDropdown.find(h => h?.label?.toLowerCase() === HubName?.toLowerCase()) ? HubName : undefined} 
              onSelect={(data, key) => selectHubHandle(data, key)}
              disabled={role==="HUBADMIN"}
            >
              {hubListDropdown?.map((hubs) => (
                <Option key={hubs?.value} value={hubs?.value}>
                  {hubs?.label}
                </Option>
              ))}
            </Select>
          </div>
        </Card>

        <Card title="Select CP" className="hub-pincode-card">
          <div className="pincode-input-container">
            <Select
              style={{ width: "90%" }}
              showSearch
              filterOption={(input, option) =>
                option?.children?.toLowerCase()?.includes(input?.toLowerCase())
              }
              placeholder="Select CP Name"
              mode="multiple"
              value={cpListData.map((cp) => cp?.premiseId)}
              onChange={handleCPSelection}
            >
              {allCPList?.map((cps) => (
                <Option key={cps.premiseId} value={cps?.premiseId}>
                  {cps.premiseName}
                </Option>
              ))}
            </Select>
            <Button type="primary" onClick={saveCp}>
              Save
            </Button>
          </div>

          <div className="pincode-list">
            {cpListData?.length > 0 ? (
              cpListData?.map((value) => (
                <div key={value?.premiseId} className="pincode-item">
                  {value?.premiseName}
                  <DeleteOutlined
                    onClick={() => showDeleteConfirm(value?.premiseId)}
                    className="delete-icon"
                  />
                </div>
              ))
            ) : (
              <p className="empty-text">No CP Added</p>
            )}
          </div>
        </Card>
      </div>
    </Card>
  );
}

export default HubCpLinking;
