/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Table, message, Drawer, Timeline, Button, Tooltip } from "antd";
import Heading from "../../../../components/Heading";
import { capitaliseWithHyphen } from "../../../../utils";
import urls from "../../../../api/urls";
import API from "../../../../api";
import bag from "../../../../assests/images/middleMile/bagging.svg";
import historyImg from "../../../../assests/images/middleMile/refresh.png";
import inScan from "../../../../assests/images/middleMile/inscan.svg";
import outScan from "../../../../assests/images/middleMile/outscan.svg";
import { APIs } from "../../../../utils/worker";

import { pdf } from "@react-pdf/renderer";
import ShipmentReportPdf from "../../../../components/ShipmentReportPdf";
import { DeleteFilled } from "@ant-design/icons";

const { TRIP_BASED_SHIPMENT_REPORT,REMOVE_BAG_FROM_LCR } = urls;

const style = {
  imgIcon: { height: "25px", width: "25px" },
  inScanIcon: { height: "30px", width: "30px" },
};
const ShipmentListReport = React.memo(() => {
  const history = useLocation();
  const [tableLoading, setTableLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceShipment, setDataSourceShipment] = useState([]);
  const [shipemntDrawerOpen, setShipmentDrawerOpen] = useState(false);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
  const [shipmentHistory, setShipmentHistory] = useState({});
  const [bagCount, setBagCount] = useState({ falseCount: 0, trueCount: 0 });
  const [bagModalText, setbagModalText] = useState("");

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }

  const columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      fixed: "left",
      // render: (a, b, idx) => serialNum(10, 1, idx),
    },

    {
      title: "Document / Bag No.",
      dataIndex: "awbNumber",
      key: "awbNumber",
      render: (text, record) => {
        return (
          <div
            className={
              record?.bag && record?.open ? "span-link align-center" : ""
            }
            onClick={() =>
              record?.bag && record?.open && openShipmentDrawer(text)
            }
          >
            {text}
            {record?.bag && (
              <img src={bag} alt="bag" style={style.imgIcon} className="ml-1" />
            )}
          </div>
        );
      },
    },
    {
      title: "LCR No.",
      dataIndex: "lcrNumber",
      key: "lcrNumber",
    },
    {
      title: "From Hub",
      dataIndex: "premiseName",
      key: "premiseName",
    },
    {
      title: "To Hub",
      dataIndex: "destinationHubName",
      key: "destinationHubName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Actions",
      key: "operation",
      fixed: "right",
      //width: 200,
      render: (record) => {
        return (
          <>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div className="span-link align-center" onClick={() => openHistoryDrawer(record)}>
                <img src={historyImg} alt="History" style={style.imgIcon} /> View History
              </div>
              {!shipemntDrawerOpen && (
                <div
                  style={{ padding: "0 5px", display: "flex", alignItems: "center" }}
                  className={history?.state?.vehicleLcrStatus == "UNLOCKED" ? "pointer" : "disabled-div"}
                  onClick={() => removeBag(record?.awbNumber)}
                >
                  <Tooltip title={history?.state?.vehicleLcrStatus !== "UNLOCKED" ? "Remove Bag" : ""}>
                    <DeleteFilled
                      style={{
                        color: history?.state?.vehicleLcrStatus !== "UNLOCKED" ? "#d9d9d9" : "#f5222d",
                        fontSize: "20px"
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getReportList();
  }, []);

  const getReportList = async () => {
    setTableLoading(true);
    let response = await API.Api(
      TRIP_BASED_SHIPMENT_REPORT + history?.state?.tripId + "/report"
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      setTableLoading(false);
      const shipmentReportDummyData = response?.response?.data;
      let temp = shipmentReportDummyData?.tripShipmentDtos?.map(
        (item, index) => {
          const latestReport =
            item?.tripShipmentReports?.[item?.tripShipmentReports?.length - 1];
          return {
            ...item,
            premiseName: capitaliseWithHyphen(latestReport?.premiseName),
            destinationHubName: capitaliseWithHyphen(
              latestReport?.destinationHubName
            ),
            status: capitaliseWithHyphen(latestReport?.status),
            time: latestReport?.time,
            date: latestReport?.date,
            bagNumber: latestReport?.bagNumber,
            key: index + 1,
          };
        }
      );

      setDataSource(temp || []);
      const bagCounts = temp?.reduce(
        (acc, obj) => {
          if (obj?.bag) {
            acc.trueCount++;
          } else {
            acc.falseCount++;
          }
          return acc;
        },
        { trueCount: 0, falseCount: 0 }
      );
      setBagCount(bagCounts);
      setTableLoading(false);
    } else {
      message.error(response?.response?.data?.message);
      setTableLoading(false);
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const openShipmentDrawer = async (text) => {
    setTableLoading(true);
    let response = await API.Api(
      TRIP_BASED_SHIPMENT_REPORT + history?.state?.tripId + "/report/" + text
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      setbagModalText(text);
      setShipmentDrawerOpen(true);
      setTableLoading(false);
      const shipmentReportDummyData = response?.response?.data;
      let temp = shipmentReportDummyData?.tripShipmentDtos?.map(
        (item, index) => {
          const latestReport =
            item?.tripShipmentReports?.[item?.tripShipmentReports?.length - 1];
          return {
            ...item,
            premiseName: capitaliseWithHyphen(latestReport?.premiseName),
            destinationHubName: capitaliseWithHyphen(
              latestReport?.destinationHubName
            ),
            status: capitaliseWithHyphen(latestReport?.status),
            time: latestReport?.time,
            date: latestReport?.date,
            bagNumber: latestReport?.bagNumber,
            key: index + 1,
          };
        }
      );

      setDataSourceShipment(temp || []);
      setTableLoading(false);
    } else {
      message.error(response?.response?.data?.message);
      setTableLoading(false);
    }
  };

  const onCloseShipmentDrawer = () => {
    setShipmentDrawerOpen(false);
  };

  const onCloseHistoryDrawer = () => {
    setHistoryDrawerOpen(false);
  };

  const openHistoryDrawer = (record) => {
    setShipmentHistory(record);
    setHistoryDrawerOpen(true);
  };

  // const handleDownload = async () => {
  //   // const token = "YOUR_BEARER_TOKEN"; // Replace with the actual token retrieval logic
  //   const url = `${APIs.baseURL}/lcr-service/web/v1/lcr/download/BENGALURU-38-588`;

  //   try {
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${userData?.accessToken}`,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const blob = await response.blob();
  //     var newurl = window.URL.createObjectURL(blob);
  //     var a = document.createElement("a");
  //     console.log(newurl);
  //     a.href = newurl;
  //     a.download = "output.pdf";
  //     document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  //     a.click();
  //     a.remove();
  //   } catch (error) {
  //     console.error("Error downloading the report:", error);
  //   }
  // };
  const handleDownload = async () => {
    setTableLoading(true);

    let response = await API.Api(
      APIs.baseURL + "/lcr-service/web/v1/lcr/download/" + history.state?.tripId
    ).getWithUserNPremiseHeader();

    if (response?.status) {
      const blob = await pdf(
        <ShipmentReportPdf masterData={response?.response?.data} />
      ).toBlob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);

      link.download = `LCR_Info_(${response?.response?.data[0].vehicleLcrId}).pdf`;
      link.click();
      setTableLoading(false);
    } else {
      message.error(response?.response?.data?.message);
      setTableLoading(false);
    }
    // const blob = await pdf(<ShipmentReportPdf />).toBlob();
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(blob);
    // link.download = "vehicle_info.pdf";
    // link.click();
  };
  const removeBag = async (id) => {
    setTableLoading(true);
    let response = await API.Api(REMOVE_BAG_FROM_LCR + id).putIdHeader();
    if (response?.status) {
      setTableLoading(false);
      message.success(response?.response?.data?.message);
      getReportList();
    } else {
      setTableLoading(false);
      message.error(response?.response?.data?.message);
    }
  };
  return (
    <div className=" p-2">
      <Heading title={"Trip Report"}>
        <div className="d-flex">
          <DisplayCount
            title={"Total individual shipment"}
            bagCount={bagCount?.falseCount || 0}
          />

          <DisplayCount
            title={"Total Bag"}
            bagCount={bagCount?.trueCount || 0}
          />
          <Button
            onClick={handleDownload}
            // onClick={() =>
            //   window.open(
            //     APIs.baseURL +
            //       "/lcr-service/web/v1/lcr/download/" +
            //       history.state?.tripId
            //   )
            // }
            disabled={dataSource?.length === 0 || history?.state?.vehicleLcrStatus==="UNLOCKED"}
            className="save-btn"
            style={{ padding: "19px 15px" }}
          >
            DOWNLOAD
          </Button>
        </div>
      </Heading>
      <div className="consolidated-manifest-cntr">
        <Table
          className="lcr-inscan-container table-head-sticky"
          loading={tableLoading}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            // pageSize: 10,
            itemRender: itemRender,
            total: dataSource.length,
            // pageSizeOptions: [5, 10, 20, 50, 100],
            // current: pageNum,
          }}
        />
      </div>
      <Drawer
        zIndex={1001}
        title={`History(${shipmentHistory?.awbNumber})`}
        placement={"right"}
        width={600}
        onClose={onCloseHistoryDrawer}
        open={historyDrawerOpen}
      >
        <HistoryDesign historyList={shipmentHistory} />
      </Drawer>

      <Drawer
        zIndex={1000}
        title={`Bag Shipment List(${bagModalText})`}
        placement={"right"}
        width={1000}
        onClose={onCloseShipmentDrawer}
        open={shipemntDrawerOpen}
      >
        <Table
          className="tat-table-fm  table-head-sticky"
          loading={tableLoading}
          columns={columns}
          dataSource={dataSourceShipment}
          pagination={{
            // pageSize: 5,
            itemRender: itemRender,
            total: dataSourceShipment.length,
            // pageSizeOptions: [5, 10, 20, 50, 100],
          }}
        />
      </Drawer>
    </div>
  );
});
export default ShipmentListReport;

const HistoryDesign = React.memo(({ historyList }) => {
  return (
    <>
      <Timeline mode="left">
        {historyList?.tripShipmentReports?.map((item, i) => (
          <Timeline.Item
            style={{ marginLeft: "-13rem" }}
            color="green"
            key={`history_${i}`}
            label={
              <span>
                {item?.date} {item?.time}
              </span>
            }
          >
            {item?.status?.toUpperCase() === "INSCANNED" ? (
              <img src={inScan} alt="inscan" style={style.inScanIcon} />
            ) : item?.status?.toUpperCase() === "OUTSCAN" ? (
              <img src={outScan} alt="outscan" style={style.inScanIcon} />
            ) : item?.status?.toUpperCase() === "BAGGED" ? (
              <>
                <img src={bag} alt="bag" style={style.inScanIcon} />
                <span>
                  Bag No. <span className="fw-500">{item?.bagNumber}</span>
                </span>
              </>
            ) : (
              ""
            )}
            <span className="ml-1">
              <span className="fw-500" style={{ color: "#38548D" }}>
                {capitaliseWithHyphen(item?.status)}{" "}
              </span>
              <p style={{ marginBottom: "5px" }}>
                {item?.status?.toUpperCase() === "OUTSCAN" ? "From" : "At"}{" "}
                <span className="fw-500">
                  {capitaliseWithHyphen(item?.premiseName)}
                  {capitaliseWithHyphen(item?.destinationHubName)}
                </span>
              </p>
            </span>
          </Timeline.Item>
        ))}
      </Timeline>
    </>
  );
});

function DisplayCount({ title, bagCount }) {
  return (
    <span
      className="fw-700 mr-1"
      style={{
        background: "#D9E7F9",
        padding: "10px 15px",
        borderRadius: "5px",
        marginBottom: "5px",
      }}
    >
      <span
        style={{
          color: "#52AD50",
          fontSize: "0.92rem",
        }}
      >
        {title} :
        <span
          style={{
            color: "#000",
            fontSize: "0.92rem",
          }}
        >
          <span
            style={{
              fontSize: "0.92rem",
            }}
          >
            {` ${bagCount}`}
          </span>
        </span>
      </span>
    </span>
  );
}
