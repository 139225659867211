/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Button,
  Tooltip,
  Modal,
  message,
  TimePicker,
  Popconfirm,
  DatePicker,
  Input,
} from "antd";
import {
  capitaliseWithHyphen,
  serialNum,
  dateFormat_dmy,
  commonSorterForTable,
} from "../../../utils";
import { useNavigate } from "react-router";
import check_out_img from "../../../assests/images/middleMile/check_out.svg";
import search_icon from "../../../assests/images/search-icon.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

import { ReactComponent as Lock_icon } from "../../../assests/images/lock.svg";
import { ReactComponent as Unlock_icon } from "../../../assests/images/ulock.svg";
import { ReactComponent as Checkmark } from "../../../assests/images/checkmark.svg";

import urls from "../../../api/urls";
import API from "../../../api";
import "./style.scss";
import moment from "moment";
import {DeleteFilled, } from "@ant-design/icons";

const TripList = () => {
  const navigate = useNavigate();
  const {
    GET_ALL_LCR_TRIPS,
    POST_CHECKOUT_TIME,
    SEARCH_TRIP_LIST,
    LOCK_TRIP_LCR,
    EXIT_TRIP,
    REMOVE_EMPTY_LCR
  } = urls;
  const { RangePicker } = DatePicker;
  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tripId, setTripId] = useState(null);
  const [vehicleNo, setVehicleNo] = useState(null);
  const [checkoutTime, setCheckoutTime] = useState("");
  // const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [dateRange, setDateRange] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [totalCount,setTotalCount]=useState(0);
  const [vehicleLcrId,setVehicleLcrId]=useState();
  const tableLoading = {
    spinning: loading,
    indicator: (
      <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
    ),
  };

  const columns = [
    {
      title: "Sl No",
      align: "center",
      width: 5,
      render: (text, record, index) => {
        return (currentPage - 1) * pageSize + (index + 1);
      },
    },
    {
      title: "Vehicle/Flight LCR ID",
      dataIndex: "vehicleLcrId",
      key: "vehicleLcrId",
      width: 10,
      sorter: false,
      sortOrder: null,
      // render: (text) => <div>{text || "------"}</div>,
      render: (text, record) => (
        <span
          className="span-link"
          onClick={() =>
            navigate("/shipment-report", {   state: { 
              tripId: record?.tripId, 
              vehicleLcrStatus: record?.vehicleLcrStatus 
            } 
          })
          }>
          {text || "------"}
        </span>
      ),
    },
    {
      title: "Vehicle/Flight LCR Status",
      width: 10,

      dataIndex: "vehicleLcrStatus",
      key: "vehicleLcrStatus",
      sortOrder:
        sortedInfo.columnKey === "vehicleLcrStatus" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "vehicleLcrStatus"),
      ellipsis: true,
      render: (text) => <div>{text || "------"}</div>,
    },
    {
      title: "Mode",
      width: 10,

      dataIndex: "travelBy",
      key: "travelBy",
      sortOrder: sortedInfo.columnKey === "travelBy" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "travelBy"),
      ellipsis: true,
      render: (text) => <div>{capitaliseWithHyphen(text) || "------"}</div>,
    },
    {
      title: "Vehicle No.",
      width: 10,

      dataIndex: "",
      key: "",
      sorter: false,
      sortOrder: null,

      ellipsis: true,
      render: (text) => (
        <span>{text?.vehicleNum ? text?.vehicleNum : text?.flightNum}</span>
      ),
    },
    {
      title: "Route",
      width: 10,

      dataIndex: "routeName",
      key: "routeName",
      sortOrder: sortedInfo.columnKey === "routeName" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "travelBy"),
      ellipsis: true,
      render: (text) => (
        <span>{text ? capitaliseWithHyphen(text) : "------"}</span>
      ),
    },
    {
      title: "Co-loader",
      width: 10,
      dataIndex: "coloader",
      key: "coloader",
      sortOrder: sortedInfo.columnKey === "coloader" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "coloader"),
      ellipsis: true,
      render: (text) => <span>{text?.coloader ? text?.coloader : "NA"}</span>,
    },
    {
      title: "Driver Name",
      width: 10,
      dataIndex: "driverName",
      key: "driverName",
      sortOrder:
        sortedInfo.columnKey === "driverName" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "driverName"),
      ellipsis: true,
      render: (text) => (
        <span>{text ? capitaliseWithHyphen(text) : "------"}</span>
      ),
    },
    {
      title: "Created Date",
      width: 10,

      dataIndex: "createdDate",
      key: "createdDate",
      sortOrder:
        sortedInfo.columnKey === "createdDate" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "createdDate"),
      ellipsis: true,
      render: (text) => (
        <span>
          {`${text?.split(" ")[0]?.split("-")?.join("-")}` || "------"}
        </span>
      ),
    },
    {
      title: "Trip Status",
      width: 8,
      dataIndex: "status",
      sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "status"),
      ellipsis: true,
      key: "status",
      render: (text) => <div>{text || "------"}</div>,
    },
    
    {
      title: "Actions",
      width: 35,
      dataIndex: "",
      sorter: false,
      sortOrder: null,
      key: "",
      fixed: "right",
      ellipsis: true,
      render: (record, _record) => {
        return (
          <Space>
            {/* <Button
              className="cancel-btn"
              onClick={async () => {
                let response = await API.Api(
                  EXIT_TRIP + _record?.tripId
                ).putIdHeader();
              }}
            >
              Exit Trip
            </Button> */}
            <Button
              disabled={!_record?.isEwayBill || _record?.vehicleLcrStatus === "UNLOCKED"}
              className="cancel-btn"
              onClick={() => {
                navigate(`/hub-eway-bill/${_record.tripId}`, {
                  state: { state: _record, slug: "/lcr-generation" },
                });
              }}>
              E-WAY BILL UPDATE
            </Button>
            <Button
              className="cancel-btn"
              disabled={!_record?.isEwayBill}
              onClick={() =>
                navigate("/print-eway-bill", {
                  state: {
                    state: _record?.tripId,
                    key: "VIEW_TRIP",
                    slug: "/lcr-generation",
                  },
                })
              }>
              PRINT
            </Button>
            <div
              style={{ padding: "0 5px" }}
              className={
                _record?.status == "COMPLETED" || 
                _record?.vehicleLcrStatus === "UNLOCKED" || 
                (_record?.empty && _record?.status !== "DISCARDED") 
                  ? "pointer" 
                  : "disabled-div"
              }
              onClick={() =>!_record?.empty? lockUnlockLCR(_record?.tripId):removeEmptyLCR(_record?.tripId)}>
              <Tooltip
                title={_record?.status === "COMPLETED"?"":_record?.empty? "Remove Empty LCR":
                   _record?.vehicleLcrStatus === "LOCKED"
                    ? "UNLOCK"
                    : "LOCK"
                }>
                {_record?.status === "COMPLETED" ? (
                  <Checkmark width={25} height={25} />
                ) : _record?.empty ?<DeleteFilled style={{ color: _record?.status == "DISCARDED" ? "#d9d9d9" : "#f5222d",fontSize:"20px" }}/>:
                _record?.vehicleLcrStatus === "UNLOCKED" ? (
                  <Unlock_icon />
                ) : (
                  <Lock_icon />
                )}
              </Tooltip>
            </div>
            <Tooltip title={"CHECK-OUT"}>
              <div
                onClick={() => showModal(_record)}
                className={
                  record?.travelBy?.toUpperCase() === "AIR"
                    ? "disabled-div"
                    : "pointer"
                }>
                <img src={check_out_img} />
              </div>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const lockUnlockLCR = async (id, lock) => {
    setLoading(true);
    let response = await API.Api(LOCK_TRIP_LCR + id).putIdHeader();
    if (response?.status) {
      setLoading(false);
      getTripList(dateRange?.startDate, dateRange?.endDate,vehicleLcrId);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message);
    }
  };
  const removeEmptyLCR = async (id) => {
    setLoading(true);
    let response = await API.Api(REMOVE_EMPTY_LCR + id).putIdHeader();
    if (response?.status) {
      setLoading(false);
      getTripList(dateRange?.startDate, dateRange?.endDate,vehicleLcrId);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message);
    }
  };
  const showModal = (record) => {
    setIsModalOpen(true);
    setTripId(record?.tripId);
    setVehicleNo(record?.vehicleNum);
    // setBtnDisable(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const getTodayAndYestardayDate = (startDate, endDate) => {
    const yesterday = moment().subtract(1, "day").format("DD-MM-YYYY HH:mm");
    const today = moment().format("DD-MM-YYYY HH:mm");
    let start = startDate
      ? moment(startDate, "DD/MM/YYYY HH:mm").format("DD-MM-YYYY HH:mm")
      : null;
    let end = endDate
      ? moment(endDate, "DD/MM/YYYY HH:mm").format("DD-MM-YYYY HH:mm")
      : null;
    return `&fromDate=${start}&toDate=${end}`;
  };

  const getTripList = async (startDate, endDate,vehicleLcrId) => {
    setLoading(true);
    let urls=GET_ALL_LCR_TRIPS +`?pageNum=${currentPage - 1 || 0}&pageSize=${pageSize}`;
    if(startDate && endDate){
      urls +=  getTodayAndYestardayDate(startDate ? startDate : null,endDate ? endDate : null);
    }
    if(vehicleLcrId){
      urls +=`&vehicleLcrId=${vehicleLcrId}`;
    }
    let response = await API.Api(urls
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      setLoading(false);
      setTotalCount(response?.response?.data?.count);
      let temp = response?.response?.data?.data.map((item, index) => ({
        ...item,
        key: index + 1,
      }));

      setDataSource(temp);
    } else {
      setLoading(false);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  function paginationHandle(currentPage, pageSize) {
    // if (dateRangeStatus) {
    //   dateSearchFun(currentPage, pageSize);
    // }
    setCurrentPage(currentPage);
    setPageSize(pageSize);
  }
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const onTimeChange = (time, timeString) => {
    setCheckoutTime(timeString);
  };

  const handleOk = async () => {
    setLoading(true);
    let response = await API.Api(
      `${POST_CHECKOUT_TIME}check_out/${tripId}`
    ).postWithPrmiseHeader({
      vehicleNum: vehicleNo,
      checkOutTime: checkoutTime,
    });
    if (response?.status) {
      message.success("successfully checked out");
      setLoading(false);
      setIsModalOpen(false);
    } else {
      setLoading(false);
      setIsModalOpen(false);
      message.error(response?.response?.data?.message);
    }
  };

  const handleDateChange = (params, date) => {
    const startDate = params[0];
    const endDate = params[1];
    const diffInDays = endDate.diff(startDate, "days");
    // if (diffInDays > 7) {
    //   message.error("You can only select a date range of 7 days or less!");
    //   return;
    // }
    if(params !==null){
      setDateRange({ startDate: date[0], endDate: date[1] });
    }else{
      setDateRange({ startDate: "", endDate: "" });
    }
  };

    const disabledDate = (current) => {
        return current && current > moment().endOf("day");
    };
    const onSearch = (e) => {
      if (e.target.value.length > 10 && e.target.value.length < 15) {
        setVehicleLcrId(e.target.value);
        setCurrentPage(1);
      } else {
        setVehicleLcrId("");
        setCurrentPage(1);
      }
    };
    useEffect(() => {
      getTripList(dateRange?.startDate, dateRange?.endDate,vehicleLcrId);
    }, [currentPage, pageSize,dateRange,vehicleLcrId]);  
  return (
    <div className="bagging-shipment" style={{ padding: "0" }}>
      <div className="lcr-table-container">
        <div className="flex-end" style={{ padding: "0 10px 5px 10px" }}>
          <div className="center">
            <Input
              className="mr-1"
              placeholder="Enter Vehicle/Flight LCR to search"
              onChange={onSearch}
              bordered
              style={{
                borderRadius: "5px",
                height: "40px",
              }}
              suffix={<img src={search_icon} />}
            />
          </div>
          <RangePicker
            disabledDate={disabledDate}
            onChange={handleDateChange}
            format={"DD/MM/YYYY :HH:mm"}
            showTime={"HH:mm"}
            // defaultValue={[
            //   moment(new Date(), dateFormat_dmy),
            //   moment().subtract(1, "days"),
            //   dateFormat_dmy,
            // ]}
          />
        </div>
        <Table
          className="table-head-sticky"
          loading={tableLoading}
          columns={columns}
          dataSource={dataSource}
          scroll={{
            x: 1800,
            y: 500,
          }}
          onChange={handleChange}
          pagination={{
            showSizeChanger: true, 
            pageSize: pageSize,
            itemRender: itemRender,
            total: totalCount,
            pageSizeOptions: [25,50,75,100,125,150],
            current: currentPage,
            onChange: paginationHandle,
          }}
        />
        <Modal
          className="timepicker-modal"
          title="Check-out"
          open={isModalOpen}
          maskClosable={false}
          footer={false}
          onCancel={handleCancel}
          destroyOnClose>
          <TimePicker onChange={onTimeChange} format={"HH:mm"} />
          <div className="flex-end mt-1">
            <Button onClick={handleCancel} className="cancel-btn mr-1">
              {"CANCEL"}
            </Button>
            <Popconfirm
              title="Do you wish to check-out"
              onConfirm={handleOk}
              okText="Yes"
              cancelText="No"
              style={{ background: "none" }}>
              <Button
                className="save-btn"
                // disabled={btnDisable}
              >
                {"SAVE"}
              </Button>
            </Popconfirm>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default TripList;
