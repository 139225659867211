/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Input,
  Select,
  Form,
  Spin,
  Space,
  message,
} from "antd";

import {
  capitaliseWithHyphen,
  // checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
  isValidAwb,
  isValidChildAwb,
} from "../../../../utils";
import API from "../../../../api";
import nodeURLs from "../../../../api/nodeUrls";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";

const {
  GET_EXCEPTION_ACTIONS,
  UPDATE_EXCEPTION_REPORT,
  GET_EXCEPTION_REPORT,

  GET_EXCEPTION_REASONS,
} = nodeURLs;

// import no_shipment from "../../../assests/images/middleMile/no-shipment.svg";
// import { ReactComponent as Scan_check } from "../../../assests/images/SVG/check.svg";

const ExpectionModal = ({
  visible,
  setVisible = () => {},
  type,
  handleCallBack = () => {},
  editAwbData,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showReason, setShowReason] = useState("");
  const userId = JSON.parse(localStorage.getItem("userId"));
  const userName = JSON.parse(localStorage.getItem("userName"));
  const [actionData, setActionsData] = useState([]);
  const [reasonsData, setReasonsData] = useState();
  const { Option } = Select;
  // const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      setShowReason("");
      getActions();
    }
  }, [visible]);

  function onClose() {
    form.resetFields();
    setVisible(false);
  }

  const getActions = async () => {
    setLoading(true);

    const response = await API.Api(GET_EXCEPTION_ACTIONS).NodeGetWithPremise();

    if (response?.status) {
      setLoading(false);
      form.setFieldsValue({
        hubName: userName || "",
        awbNumber: editAwbData?.awb_number || null,
      });
      setActionsData(response?.response?.data?.actions);
    } else {
      setLoading(false);
      message.error(response?.statusText || "Internal server error");
    }
  };

  const onFinish = async (data) => {
    console.log(data);
    setLoading(true);

    let payload = {
      hubId: userId?.userInfo?.premiseId,
      actionId: +data?.actionId,
      awbNumber: data?.awbNumber,
      reason: data.reason,
      shipmentType: type == "shipment" ? "Shipment" : "Bag",
    };

    const response = await API.Api(
      GET_EXCEPTION_REPORT
    ).NodePostPremiseIdHeader(payload);

    if (response?.status) {
      setVisible(false);
      form.resetFields();
      handleCallBack();
      message.success(response?.data?.message || "Added successfully");
    } else {
      form.resetFields();
      setLoading(false);
      message.error(response?.response?.statusText || "Internal server error");
    }
  };

  // const getReasons = async () => {
  //   setLoading(true);

  //   const response = await API.Api(GET_EXCEPTION_REASONS).NodeGetWithPremise();

  //   if (response?.status) {
  //     setLoading(false);
  //     // console.log(response, "response");
  //     setReasonsData(response?.response?.data?.actions);
  //   } else {
  //     setLoading(false);
  //     message.error(response?.statusText || "Internal server error");
  //   }
  // };

  // function handleAction(params, record) {
  //   if (
  //     record?.children?.toLowerCase()?.includes("discard") ||
  //     record?.children?.toLowerCase()?.includes("damage")
  //   ) {
  //     setShowReason(true);
  //     getReasons();
  //     // console.log("hello dicard");
  //   } else {
  //     setShowReason(false);
  //   }
  // }

  const getReasons = async () => {
    setLoading(true);

    const response = await API.Api(GET_EXCEPTION_REASONS).NodeGetWithPremise();

    if (response?.status) {
      setLoading(false);
      // console.log(response, "response");
      setReasonsData(response?.response?.data?.actions);
    } else {
      setLoading(false);
      message.error(response?.statusText || "Internal server error");
    }
  };

  function handleAction(params, record) {
    console.log(params, record, "REC");
    if (record?.children?.toLowerCase()?.includes("damage")) {
      setShowReason("damage");
      getReasons();
    } else if (record?.children?.toLowerCase()?.includes("discard")) {
      setShowReason("discard");
    } else {
      setShowReason("");
    }
  }

  return (
    <Modal
      title={`${editAwbData ? "Update" : "Add New"} Exceptions`}
      open={visible}
      width={350}
      footer={null}
      className="create-bag"
      style={{ top: "200px !important" }}
      onCancel={() => {
        setVisible(false);
      }}
      maskClosable={false}
    >
      <Spin
        spinning={loading}
        size="large"
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  type == "shipment" ? "Add shipment number" : "Add bag number"
                }
                name="awbNumber"
                rules={[
                  {
                    required: true,
                    message: "Enter awb number!",
                  },
                  {
                    validator: (_, value) => {
                      // if (value && !isValidAwb(value)) {
                      //   return Promise.reject("Enter valid awb number");
                      // } else {
                      //   return Promise.resolve();
                      // }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  // className="input-without-form"
                  placeholder="Enter awb number"
                  autoComplete="off"
                  // maxLength={14}
                  onKeyPress={(event) => {
                    if (
                      // checkNumbervalue(event) ||
                      checkSpace(event) ||
                      checkSpecialCharacter(event)
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="mt-1">
              <Form.Item
                label="Hub name"
                name="hubName"
                rules={[{ required: true, message: "Enter hub name!" }]}
              >
                <Input
                  // className="input-without-form"
                  placeholder="Hub Name"
                  autoComplete="off"
                  disabled
                  onKeyPress={(event) => {
                    if (checkSpace(event) || checkSpecialCharacter(event)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="mt-1 mb-1">
              <Form.Item
                label={"Select status"}
                name="actionId"
                rules={[{ required: true, message: "Select action!" }]}
              >
                <Select
                  // className="select-without-form"
                  placeholder="Select action"
                  // defaultValue=""
                  onSelect={handleAction}
                  // options={actionData}
                >
                  {actionData?.map((item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {showReason === "damage" ? (
              <Col span={24} className=" mb-1">
                <Form.Item
                  label={"Reason"}
                  name="reason"
                  rules={[{ required: true, message: "Select reason!" }]}
                >
                  <Select
                    // className="select-without-form"
                    placeholder="Select reason"
                    // defaultValue=""
                    // options={actionData}
                  >
                    {reasonsData
                      ?.filter((e) => e?.allowFor?.includes("shipment"))
                      .map((item) => (
                        <Option key={item?.id} value={item?.reason}>
                          {item?.reason}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : showReason === "discard" ? (
              <Col span={24} className=" mb-1">
                <Form.Item
                  name="reason"
                  label="Reason"
                  rules={[
                    {
                      required: true,
                      message: "Please input reason",
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={100} />
                </Form.Item>{" "}
              </Col>
            ) : null}

            <Col span={24}>
              <Form.Item>
                <Space style={{ width: "100%", justifyContent: "end" }}>
                  <Button className="cancel-btn" onClick={onClose}>
                    CANCEL
                  </Button>
                  <Button className="save-btn" type="primary" htmlType="submit">
                    SUBMIT
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ExpectionModal;
