/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  Spin,
  message,
  Modal,
} from "antd";
import { useNavigate } from "react-router-dom";
import Smcs_New_Logo from "../../assests/images/Smcs_New_Logo.png";
import Smcs_Loader from "../../assests/images/loader-new.gif";
import { WarningTwoTone } from "@ant-design/icons";
import axios from "axios";
// import Cookies from "universal-cookie";

import {
  getAccountService,
  loginUser,
} from "../../actionsMethods/cashBookingMethods";

import "antd/dist/antd.css";
import "./index.scss";

const NormalLoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const getLoginResponse = useSelector(
    (state) => state.appReducer.loginUser,
    shallowEqual
  );

  const getAccountServiceResponse = useSelector(
    (state) => state?.appReducer?.getAccountServiceReducer,
    shallowEqual
  );

  const { error, fetching, result } = getLoginResponse;

  const {
    fetching: accountFetching,
    result: accountResult,
    error: accountError,
  } = getAccountServiceResponse;

  function addHours(date, hours) {
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    return date;
  }
  useEffect(() => {
    if (result !== null) {
      localStorage.setItem("userinfo", JSON.stringify(result));

      const object = { name: result.accessToken, email: result.username };
      document.cookie = "userToken=" + JSON.stringify(object);
      let sec = result?.expiresIn;
      let hour = parseInt(sec / 3600);
      const result1 = addHours(new Date(), hour);
      localStorage.setItem("loggedOutTime", JSON.stringify(result1.getTime()));
    } else {
      setErrorMessage(error?.message);
    }
  }, [result, error, fetching]);

  useEffect(() => {
    if (result) {
      setLoading(true);
      dispatch(getAccountService(result?.username, result?.accessToken));
    }
  }, [result]);

  useEffect(() => {
    if (accountError) {
      setLoading(false);
      message.error(accountError?.message);
    }
  }, [accountError]);

  useEffect(() => {
    if (accountResult !== null) {
      localStorage.setItem("userId", JSON.stringify(accountResult));
      localStorage.setItem(
        "userName",
        JSON.stringify(accountResult?.userInfo?.firstName)
      );
      // navigate("/dashboard");
      let url = localStorage.getItem("viewClient");
      if (url !== undefined && url !== null) {
        window.location.replace(url);
      } else {
        window.location.replace("/dashboard");
      }
    }
  }, [getAccountServiceResponse]);

  const onFinish = async (values) => {
    delete values.remember;
    // emailInput.value.trim()
    const payload = {
      ...values,
      username: values.username.trim(),
    };
    dispatch(loginUser(payload));
  };

  const handleForgot = () => {
    navigate("/forgotPassword");
  };

  // const loginHit = () => {

  //   for (let i = 0; i < data.length - 1; i++) {
  //     axios
  //       .post(
  //         "https://devhubopsapis.innofulfill.com/smcs-webapp/api/users/sign-up",
  //         {
  //           username: data[i],
  //           email: data[i],
  //           password: "Admin@123",
  //         }
  //       )
  //       .then((res) => {})
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  return (
    <div className="login-main-container">
      <Spin
        spinning={fetching || loading || accountFetching}
        size="large"
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }>
        <Row>
          <Col
            className="img-parent"
            xl={12}
            lg={12}
            md={12}
            sm={0}
            xs={24}
            // className={"logibg-container"}
          >
            <div className="img-div"></div>
          </Col>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            // style={{
            //   margin: "0 auto",
            //   position: "relative",
            //   backgroundColor: " #ffffff",
            // }}
            className="login-parent">
            <div className="login-container">
              <div className="small-screen-logo">
                <img
                  src={Smcs_New_Logo}
                  alt="smcs-logo"
                  className="login-img"
                />
              </div>
              <div className="login-container-headding">
                Login To Your Account
              </div>
              <div className="login-container-subheadding">
                Prompt, Quick & Reliable Services
              </div>
              <div className="error">
                <p>{errorMessage}</p>
              </div>
              <Form
                // style={{ marginTop: "5%" }}
                onFinish={onFinish}
                autoComplete="on"
                initialValues={
                  {
                    // username: "priyaranjan@hutechsolutions.com",
                    // password: "Hutech@1234",
                    // name:"sdhjdhhjs"
                  }
                }
              >
                <div
                  className="login-container-email"
                  style={{ marginTop: 20 }}>
                  Email
                </div>

                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Enter your email address",
                    },
                  ]}
                >
                  <Input
                    className="login-container-email-input"
                    placeholder="Enter your email address"
                    // maxLength={50}
                    bordered={false}
                  />
                </Form.Item>
                <div
                  className="login-container-email"
                  style={{ marginTop: "30px" }}>
                  Password
                </div>
                <div>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Enter your password",
                      },
                    ]}>
                    <Input.Password
                      className="login-container-email-input"
                      placeholder="Password"
                      bordered={false}
                    />
                  </Form.Item>
                </div>
                <div className="login-container-remember">
                  {/* <div
                    style={{
                      display: "flex",
                      columnGap: "10px",
                    }}
                  >
                    <Checkbox
                      onChange={(e) => setRememberMe(!rememberMe)}
                      onKeyPress={(e) => {
                        if (e.key == "Enter") {
                          setRememberMe(!rememberMe);
                          e.preventDefault();
                        }
                      }}
                      defaultChecked={rememberMe}
                      checked={rememberMe}
                    />
                    <span className="rememberMe">Remember me</span>
                  </div> */}
                  <div
                    className="login-container-forgot"
                    onClick={handleForgot}
                  >
                    Forgot Password ?
                  </div>
                </div>

                <Form.Item>
                  <div className="login-container-btndiv">
                    {" "}
                    <Button
                      className="save-btn mt-2"
                      size="large"
                      htmlType="submit"
                      block
                    >
                      LOGIN
                    </Button>
                  </div>
                </Form.Item>
              </Form>
              {/* <Button onClick={() => loginHit()}>Login</Button> */}
              <div
                className="footer"
                style={{
                  position: "sticky",
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  // border: "2px solid red",
                  // width: "70%",
                }}>
                <p>
                  Copyright © {new Date().getFullYear()} Shree Maruti Integrated
                  Logistics Limited
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
      {/* <Modal title="" open={true} footer={null}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <WarningTwoTone style={{ fontSize: "10rem" }} />
          <p style={{ color: "red", fontSize: "2rem" }}>Under Maintenance!</p>
        </div>
      </Modal> */}
    </div>
  );
};

export default NormalLoginForm;
