/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, Input, Form, Button, Table, Spin, message, Select } from "antd";
import "./style.scss";
import API from "../../../api";
import urls from "../../../api/urls";
import { capitalize, commonSorterForTable, serialNum } from "../../../utils";
import Heading from "../../../components/Heading";
import { useLocation, useNavigate } from "react-router-dom";
import UpdateIcon from "../../../assests/images/updateEway.svg";
import { useParams } from "react-router-dom";
import { hubEwayData } from "../../../store/hubEway";
import whiteUpdateIcon from "../../../assests/images/whiteUpdateEway.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import search_icon from "../../../assests/images/search-icon.svg";

const {
  HUBEWAYBILL,
  CONSOLIDATE_EWAY_BILL,
  EWAYBILL_UPDATE_PARTB,
  AUDIT_EWAY_BILL,
  UPDATED_EWAY_BILL,
} = urls;

const travelTypes = [
  { label: "Air", value: "air" },
  { label: "Surface", value: "Surface" },
];

const transportModes = [
  { label: "Road", value: "Road" },
  { label: "Rail", value: "Rail" },
];
const transportModesAIR = [
  { label: "Road", value: "Road" },
  { label: "Air", value: "Air" },
];
const HubEWayBill = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const history = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [dataSource, setdataSource] = useState(tableData);
  const [sortedInfo, setSortedInfo] = useState({});

  const [inputSearchValue, setInputSearchValue] = useState("");
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [updatedEwayData, setUpdatedEwayData] = useState([]);
  const [travelTypeMode, setTravelTypeMode] = useState("surface");
  const [transportMode, setTransportMode] = useState("");
  

  const handleTransportModeChange = (value) => {
    setTransportMode(value);
    getUpdatedEwayBills();
  };
  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  let vehicleNumber =history?.state?.state?.vehicleNum;
  let driverName = history?.state?.state?.driverName;
  let travelBy= history?.state?.state?.travelBy;
  let type= history?.state?.state?.type !== "N/A" ? history?.state?.state?.type:"Road";
  let trainNo= history.state.state.trainNo;
  let flightNum= history?.state?.state?.flightNum;

  useEffect(() => {
    setTransportMode(history?.state?.state?.type !== "N/A" ? history?.state?.state?.type:"Road");
  }, [history?.state?.state]);

  function getModifiedGST(item) {
    const gstNumber = item?.premises?.gst;
    const firstTwoDigits = gstNumber?.substring(0, 2);
    const modifiedDigits = firstTwoDigits?.startsWith("0")
      ? firstTwoDigits?.substring(1)
      : firstTwoDigits;
    return modifiedDigits;
  }

  const columns = [
    {
      title: "SI No",
      dataIndex: "",
      key: "SI",
      width: 15,
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 10,
    },
    {
      title: "AWb No",
      dataIndex: "awbNumber",
      key: "awbNumber",
      width: 40,
      render: (text) => {
        return text;
      },
    },
    {
      title: "E-way Bill No",
      width: 30,
      dataIndex: "ewayBillNumber",
      key: "ewayBillNumber",
    },
    {
      title: "Destination Hub",
      dataIndex: "toHub",
      key: "toHub",
      width: 40,
      sorter: (a, b) => commonSorterForTable(a, b, "toHub"),
      sortOrder: sortedInfo?.columnKey === "toHub" ? sortedInfo?.order : null,
      ellipsis: true,
      render: (record, _data) => {
        return capitalize(record);
      },
    },
    {
      title: "Travel Type",
      width: 40,
      dataIndex: "travelBy",
      key: "travelBy",
      render: (record, _data) => {
        return capitalize(record);
      },
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      width: 60,
      render: (data, record) => {
        return (
          <div>
            {record?.ewayUpdated ? (
              <Button
                className=""
                // disabled={record?.ewayUpdated}
                style={{
                  borderRadius: "5px",
                  color: "white",
                  background: "#149D11",
                  cursor: "not-allowed",
                }}
              >
                <img src={whiteUpdateIcon} />
                <span className="ml-1 fw-500">E-WAY BILL UPDATED</span>
              </Button>
            ) : (
              <Button
                className=""
                // disabled={record?.ewayUpdated}
                style={{
                  borderRadius: "5px",
                  color: "#2E77D0",
                  background: "white",
                }}
                onClick={() => SingleConsolidate(record)}
              >
                <img src={UpdateIcon} />
                <span className="ml-1 fw-500">E-WAY BILL UPDATE</span>
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;

  useEffect(() => {
    getUpdatedEwayBills();
  }, []);

  useEffect(() => {
    getEwayBills();
  }, [updatedEwayData]);

  const getUpdatedEwayBills = async () => {
    setLoading(true);
    let response = await API.Api(
      `${UPDATED_EWAY_BILL}${params?.id}`
    ).getWithAllHeaders();
    if (response?.status) {
      setLoading(false);
      setUpdatedEwayData(response?.response?.data?.ewaybill);
    } else {
      setLoading(false);
    }
  };

  function printShipmentAwbNumbers(data) {
    const modifiedData = [];
    data?.stopLcrs?.forEach((stopLcr) => {
      const viewLCREwayShipment = stopLcr?.viewLCREwayShipment;
      viewLCREwayShipment?.shipmentLists?.forEach((shipment) => {
        if (shipment?.ewayBillNumber) {
          const ewayBillNumbers = shipment?.ewayBillNumber?.split(",");
          ewayBillNumbers.forEach((billNumber) => {
            modifiedData.push({
              ...shipment,
              awbNumber: shipment?.awbNumber || shipment?.childBarcode,
              ewayBillNumber: billNumber.trim(),
              tripId: data?.tripId,
              toHub: viewLCREwayShipment?.toHub,
              travelBy: viewLCREwayShipment?.travelBy?.toLowerCase(),
            });
          });
        }
      });
    });

    const groupedData = modifiedData.reduce((result, item) => {
      const existingItem = result.find(
        (el) => el.ewayBillNumber === item.ewayBillNumber
      );
      if (existingItem) {
        existingItem.awbNumber;
      } else {
        result.push({ ...item });
      }
      return result;
    }, []);

    return groupedData;
  }

  const getEwayBills = async (e) => {
    setLoading(true);
    let response = await API.Api(HUBEWAYBILL + params?.id).getWithAllHeaders();
    if (response) {
      setLoading(false);
      const temp = response?.response?.data?.data;
      const getShipments = printShipmentAwbNumbers(temp);
      // for (let i = 0; i < getShipments?.length; i++) {
      //   if (updatedEwayData?.includes(getShipments[i]?.ewayBillNumber)) {
      //     getShipments[i].ewayUpdated = true;
      //   } else {
      //     getShipments[i].ewayUpdated = false;
      //   }
      // }
      for (let i = 0; i < getShipments?.length; i++) {
        const shipment = getShipments[i];
        const isUpdated = updatedEwayData.some(
          (data) => data.ewaybillNo === shipment.ewayBillNumber && data.type === transportMode
        );
        getShipments[i].ewayUpdated = isUpdated;
      }
      setTableData(getShipments);
      setTravelTypeMode(getShipments[0]?.travelBy == "surface" ? "1" : "3");
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message);
    }
  };

  const handleSubmit = async (params) => {
    setLoading(true);

    const stateGST = getModifiedGST(userId);

    if (selectedRowKeys?.length > 0) {
      // Map selected row keys to get the data
      let temp = selectedRowKeys?.map((val) => {
        const record = tableData.find((item) => item.ewayBillNumber === val);
        return { ewbNo: val, awbNumber: record?.awbNumber };  // Add awbNumber here
      });

      let payload = {
        fromPlace: userId?.premises?.city,
        fromState: +stateGST,
        vehicleNo: vehicleNumber,
        transMode: transportMode == "Air" ? "3" : transportMode == "Rail" ? "2" : "1",
        transDocDate: params?.transDocDate, //same format
        tripSheetEwbBills: temp,
      };
      let response = await API.Api(CONSOLIDATE_EWAY_BILL).postIdHeader(payload);
      if (response?.response?.data?.status == "1") {
        if(typeof response?.response?.data?.data === "string" && typeof JSON.parse(response?.response?.data?.data) !== "object"){
          setLoading(false);
          message.error(response?.response?.data?.message || "Failed to Update E-way bill");
          return;
        }
        setLoading(false);
        message.success(
          response?.response?.data?.message || "E-way Bill Updated Successfully"
        );
        if(response?.response?.data.failedEwayBills?.length > 0){
          let failedEwayBills = response?.response?.data?.failedEwayBills;
          let failedEwayBillNumbers = failedEwayBills?.map((item) => item.ewbNo);
          let EwayBillData = selectedRowKeys.filter((item) => !failedEwayBillNumbers?.includes(item));
          auditEwayBill(response?.response?.data, EwayBillData, "consolidate");
        }else{
          auditEwayBill(response?.response?.data, selectedRowKeys, "consolidate");
        }
        setSelectedRowKeys([]);
      } else {
        setLoading(false);
        message.error(
          response?.response?.data?.data || "Failed to Update E-way bill"
        );
      }
    } else {
      message.error("Select an E-way bill");
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.ewayUpdated,
      name: record.name,
    }),
    selectedRowKeys: selectedRowKeys,
  };

  const SingleConsolidate = async (params) => {
    setLoading(true);
    const stateGST = getModifiedGST(userId);

    let payload = {
      ewbNo: params?.ewayBillNumber,
      awbNumber: params?.awbNumber,   
      vehicleNo: vehicleNumber,
      fromPlace: userId?.premises?.city,
      fromState: +stateGST,
      transDocDate: formattedToday,
      transMode: transportMode == "Air" ? "3" : transportMode == "Rail" ? "2" : "1",
    };
    let response = await API.Api(
      EWAYBILL_UPDATE_PARTB + params?.ewayBillNumber + "/partB"
    ).patchIdHeader(payload);
    if (response?.response?.data?.status == "1") {
      setLoading(false);
      message.success(
        response?.response?.data?.message || "E-way Bill Updated Successfully"
      );
      auditEwayBill(response?.response?.data, params, "single");
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.data || "Failed to Update E-way bill"
      );
    }
  };

  async function auditEwayBill(singleUpdateRes, record, type) {
    setLoading(true);

    function filterDataByAwbNumbers(dataArray, awbNumbersArray) {
      return dataArray?.filter((item) =>
        awbNumbersArray?.includes(item?.ewayBillNumber)
      );
    }
    let temp = [];
    let payload = {};
    if (type == "single") {
      temp = tableData?.filter(
        (val) => val?.ewayBillNumber === record?.ewayBillNumber
      );
      payload = {
        shipments: temp?.map((val) => {
          return {
            ewbBillExpDate: val?.expiryDate,
            awbcrtDate: val?.bookingCompleteTime,
            ewaybillNo: val?.ewayBillNumber,
            ewayBillCrtDate: val?.ewayBillCreateDate || null,
            shipmentValue: val?.value,
            awbnumber: val?.awbNumber,
          };
        }),
        tripid: params?.id,
        consEwbNo: "0",
        vehicleNo: vehicleNumber,
        transMode: travelTypeMode == "1" ? "surface" : "air",
        ewbOprPlace: userId?.premises?.city,
        type:transportMode == "Air" ? "2" : transportMode == "Rail" ? "1" : "0",
      };
    } else {
      let filteredData = filterDataByAwbNumbers(tableData, record);

      let temp = singleUpdateRes?.data;
      let cNum = JSON.parse(temp);

      payload = {
        shipments: filteredData?.map((val) => {
          return {
            awbnumber: val?.awbNumber,
            ewaybillNo: val?.ewayBillNumber,
            ewayBillCrtDate: val?.ewayBillCreateDate || null,
            ewbBillExpDate: val?.expiryDate,
            awbcrtDate: val?.bookingCompleteTime,
            shipmentValue: val?.value,
          };
        }),
        tripid: params?.id,
        consEwbNo: cNum?.cEwbNo,
        vehicleNo: vehicleNumber,
        transMode: travelTypeMode == "1" ? "surface" : "air",
        ewbOprPlace: userId?.premises?.city,
        type:transportMode == "Air" ? "2" : transportMode == "Rail" ? "1" : "0",
      };
    }

    const response = await API.Api(AUDIT_EWAY_BILL).postIdHeader(payload);

    if (response.status) {
      setLoading(false);
      getUpdatedEwayBills();
    } else {
      setLoading(false);
    }
  }
  const finishBtnHandler = () => {
    if (history?.state?.slug === "/lcr-generation") {
      navigate(history?.state?.slug, {
        state: {
          page: "2",
          key: "HUB_EWAY_BILL",
        },
      });
    } else {
      navigate(history?.state?.slug);
    }
  };

  const filterArray = (data, currValue) => {
    return data?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue?.toLowerCase())
      )
    );
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue?.length > 0 || inputSearchValue?.length == 0
        ? filterArray(tableData, currValue)
        : filterArray(tableData, currValue);
    setdataSource(filteredData);
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="m-2">
        <Heading title={"Hub E-way Bill"} />
        <div className="hub-eway-container p-2">
          <Form
            form={form}
            onFinish={handleSubmit}
            name="accountBasicDetails"
            layout="vertical"
            initialValues={{
              transDocDate: formattedToday,
              tripLCRID: params?.id,
              driverName: driverName || "",
              vehicleNo: vehicleNumber,
              travelType: travelBy,
              transportMode: type,
              flightNum:flightNum,
              trainNo:trainNo
            }}
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}
          >
            {tableData?.length ? (
              <>
                <Row justify="left" gutter={16} className="m-1">
                  <Col span={4} sm={24} md={4} lg={4} xs={24}>
                    <Form.Item
                      name="transDocDate"
                      label="Date"
                      rules={[
                        {
                          required: false,
                          message: "Select Date",
                        },
                      ]}
                    >
                      <Input placeholder="Date" disabled />
                    </Form.Item>
                  </Col>

                  <Col span={6} sm={20} md={6} lg={5} xs={20}>
                    <Form.Item
                      name="tripLCRID"
                      label="Trip LCR ID"
                      rules={[
                        {
                          required: true,
                          message: "Enter Trip LCR ID",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Trip LCR ID" disabled />
                    </Form.Item>
                  </Col>

                  <Col span={3} sm={24} md={3} lg={3} xs={24}>
                    <Form.Item
                      name="travelType"
                      label="Travel Type"
                    >
                      <Select options={travelTypes} defaultValue={travelBy} placeholder="Select Travel Type" disabled/>
                    </Form.Item>
                  </Col>

                  <Col span={4} sm={24} md={4} lg={4} xs={24}>
                    <Form.Item
                      name="transportMode"
                      label="Transport Mode"
                      rules={[
                        {
                          required: true,
                          message: "Select Transport Mode",
                        },
                      ]}
                    >
                      <Select
                        options={(history?.state?.state?.type=="N/A" || history?.state?.state?.type=="Road") ? [{ label: "Road", value: "Road" }] : travelBy === "Air" ? transportModesAIR : transportModes}
                        placeholder="Select Transport Mode"
                        onChange={handleTransportModeChange}
                        defaultValue={type}
                      />
                    </Form.Item>
                  </Col>
                  {transportMode === "Rail" && (
                    <Col span={4} sm={24} md={4} lg={4} xs={24}>
                      <Form.Item
                        name="trainNo"
                        label="Rail Number"
                        rules={[
                          {
                            required: true,
                            message: "Enter Rail Number",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Rail Number" defaultValue={trainNo} disabled/>
                      </Form.Item>
                    </Col>
                  )}
                  {transportMode === "Air" && (
                    <Col span={4} sm={24} md={4} lg={4} xs={24}>
                      <Form.Item
                        name="flightNum"
                        label="Flight Number"
                        rules={[
                          {
                            required: transportMode === "Air",
                            message: "Enter Flight Number",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Flight Number" defaultValue={flightNum} disabled/>
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={4} sm={24} md={4} lg={4} xs={24}>
                    <Form.Item
                      name="vehicleNo"
                      label="Vehicle Number"
                      rules={[
                        {
                          required: true,
                          message: "Enter Vehicle Number",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Vehicle Number"
                        defaultValue={vehicleNumber}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              <Row justify="end mb-2">
                <h2 style={{ margin: "auto" }}>No E-way bills present</h2>
              </Row>
            )}
          </Form>
        </div>
        <div className="p-2 hub-eway-container">
          <Row justify="space-between">
            <Input
              placeholder="Search by AWB, Eway number"
              bordered
              onChange={onSearch}
              style={{
                borderRadius: "5px",
                height: "40px",
                maxWidth: "250px",
                marginBottom: "20px",
              }}
              suffix={<img src={search_icon} />}
            />
            <Button
              className="save-btn"
              htmlType="submit"
              onClick={form.submit}
            >
              CONSOLIDATE UPDATE
            </Button>
          </Row>
          <Table
            onChange={handleChange}
            columns={columns}
            scroll={{ x: 500 }}
            dataSource={inputSearchValue?.length == 0 ? tableData : dataSource}
            pagination={false}
            rowKey={(record) => record?.ewayBillNumber}
            rowSelection={{
              ...rowSelection,
            }}
          />
        </div>

        {/* <Row justify="end mt-2">
          <Button className="save-btn" onClick={finishBtnHandler}>            
          NEXT          
          </Button>        
        </Row> */}      
      </div>
    </Spin>
  );
};
export default HubEWayBill;
