/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React from "react";
import { Card, Col } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import next_arrow from "../../../../assests/images/master/next-arrow.svg";
import Heading from "../../../../components/Heading";
import constants from "../../../../constants/constants";

const { ROLES } = constants;
const { CP_ADMIN, HO_ADMIN, HUB_ADMIN, ROLE_HOMARKETING } = ROLES;


const ReportTiles = () => {
    const navigate = useNavigate();
    const hubDetails = JSON.parse(localStorage.getItem("userId"));
    const hubRole = JSON.parse(localStorage.getItem("userinfo"));

    const handleNavigation = (report) => {
        navigate("/all_reports", { state: { embeddedId: report.embeddedId, rsl: report.rls } });
    };
    const reports = [
        {
            id: 1, name: "Booking vs Audited Report", embeddedId: "2816171b-9d99-4555-bc03-d8b720a38fca", rls: [{
                clause: `Hub_name = '${hubDetails.premises.premiseName}'`,
                dataset: 833
            }], role: [HUB_ADMIN, HO_ADMIN]
        },
        {
            id: 2, name: "Booking Data vs Status Report", embeddedId: "6e8d8294-a209-487f-bff8-da8ae08ed5f3", rls: [{
                clause: `From_hub = '${hubDetails.premises.premiseName}'`,
                dataset: 840
            }], role: [HUB_ADMIN, HO_ADMIN]
        },
        {
            id: 3, name: "Booking vs InScan OutScan Report", embeddedId: "85cfdc8e-16f3-44c5-bc5d-8c1c20333bd8", rls: [{
                clause: `To_hub = '${hubDetails.premises.premiseName}'`,
                dataset: 834
            }], role: [HUB_ADMIN, HO_ADMIN]
        },
        {
            id: 4, name: "E-way Bill report", embeddedId: "a988352d-3d84-4195-9b08-98a9812e2809", rls: [
                {
                    clause: `Parent_hub = '${hubDetails.premises.premiseName}'`,
                    dataset: 832
                }
            ], role: [HUB_ADMIN, HO_ADMIN]
        },
        {
            id: 5, name: "Hub Audit Entry Report", embeddedId: "e75c602c-d12f-4df4-a686-c392fc2ae11d", rls: [
                {
                    clause: `Audited_hub = '${hubDetails.premises.premiseName}'`,
                    dataset: 839
                }
            ], role: [HUB_ADMIN, HO_ADMIN]
        },
        {
            id: 6, name: "Incoming Bags", embeddedId: "52057965-93aa-4214-bbc2-b432cb023092", rls: [{
                clause: `To_hub = '${hubDetails.premises.premiseName}'`,
                dataset: 828
            }], role: [HUB_ADMIN, HO_ADMIN]
        },
        {
            id: 7, name: "Incoming LCR Bag", embeddedId: "df01e2f8-e85e-4710-ba2c-cd73cc28628c", rls: [{
                clause: `From_hub = '${hubDetails.premises.premiseName}'`,
                dataset: 825
            }], role: [HUB_ADMIN, HO_ADMIN]
        },
        {
            id: 8, name: "Incoming Shipments", embeddedId: "b67bbba2-e546-4e20-8c98-2f59c24527d0", rls: [
                {
                    clause: `To_Hub = '${hubDetails.premises.premiseName}'`,
                    dataset: 917
                }
            ], role: [HUB_ADMIN, HO_ADMIN]
        },
        { id: 9, name: "Offload Report", embeddedId: "cf3072c6-516c-4634-bc0f-5fd732de39c7", rls: [{}], role: [HUB_ADMIN, HO_ADMIN] },
        {
            id: 10, name: "OutScan Bag", embeddedId: "82a353df-31fb-43b8-b3b4-f1fdf6630bf4", rls: [{
                clause: `From_hub = '${hubDetails.premises.premiseName}'`,
                dataset: 919
            }], role: [HUB_ADMIN, HO_ADMIN]
        },
        {
            id: 11, name: "OutScan LCR Bags", embeddedId: "12472d34-62bc-45c8-982c-4612a8fa1948", rls: [{
                clause: `From_hub = '${hubDetails.premises.premiseName}'`,
                dataset: 825
            }], role: [HUB_ADMIN, HO_ADMIN]
        },
        {
            id: 12, name: "OutScan LCR Shipments", embeddedId: "940e6f7a-f8d4-4ad4-b3ce-34aa38e4b37d", rls: [{
                clause: `from_hub = '${hubDetails.premises.premiseName}'`,
                dataset: 915
            }], role: [HUB_ADMIN, HO_ADMIN]
        },
        {
            id: 13, name: "OutScan Shipments", embeddedId: "22ffbade-0f8f-458d-ad80-e60cc9450b18", rls: [{
                clause: `From_Hub = '${hubDetails.premises.premiseName}'`,
                dataset: 916
            }], role: [HUB_ADMIN, HO_ADMIN]
        },
        {
            id: 14, name: "Wrong Sorting Reports", embeddedId: "75d277cb-ce51-4ff0-8e1f-e574b83390e6", rls: [{
                clause: `From_hub = '${hubDetails.premises.premiseName}'`,
                dataset: 841
            }], role: [HUB_ADMIN, HO_ADMIN]
        },
        {
            id: 15, name: "Shipments InScan vs OutScan Report", embeddedId: "a04ecebe-10af-4aef-b8d7-4c0a7128a421", rls: [{
                clause: `Hub = '${hubDetails.premises.premiseName}'`,
                dataset: 834
            }], role: [HUB_ADMIN, HO_ADMIN]
        },
    ];
    return (
        <Card className="hub-cp-container" style={{ background: "transparent", boxShadow: "none", border: "none" }}>
            <Heading title="All Reports" />
            <div style={{ display: "flex", flexWrap: "wrap", gap: "16px", padding: "20px" }}>
                {reports
                    .filter((report) => report.role.includes(hubRole?.roles[0])) // Filter reports based on role
                    .map((report) => (
                        <Col
                            xs={24} sm={12} md={8} lg={6} xl={4}
                            key={report.id}
                            onClick={() => handleNavigation(report)}
                            className="pointer master-card-col"
                        >
                            <div className="master-card-container">
                                <div className="img-wrapper">
                                    <FileTextOutlined style={{ fontSize: "40px", color: "#1890ff" }} />
                                </div>
                                <div className="div">
                                    {report.name}&nbsp;<img src={next_arrow} alt="Next" />
                                </div>
                            </div>
                        </Col>
                    ))}
            </div>
        </Card>
    );
};

export default ReportTiles;
